import React, { Component } from 'react';
import styled from 'styled-components';

import Container from './layout/Container';
import remilyLogo from '../assets/img/landing-header-logo.png';
import hamburgerImg from '../assets/img/hamburger.svg';

const NavBar = styled.div`
  background: #2C3E50;
`
const StyledNavA= styled.a`
  max-width: 20%;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 69px;
`;

const NavContainer = styled(Container)`
  max-width: 100%;
  width: 100%;
`;

const Logo = styled.img`
  height: 36.5px;
  margin-left: 3px;
`;

const NavbarNav = styled.ul`
  display: flex;
  list-style: none;
  padding: unset;

  @media (max-width: 992px) {
    display: none;
  }
`;

const MobileNav = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: ${props => (props.showMobileNav ? '0' : '-150px')};
  transition: .3s all ease-in-out;
  z-index: 100;

  @media (max-width: 992px) {
    display: flex;
  }

  li {
    list-style: none;
  }

`;

const MobileNavWrapper = styled.div`
  padding: 30px;
  background-color: #2C3E50;
  width: 150px;
`;

const MobileItem = styled.li`
  a {
    color: white;
    font-size: 16px;
    line-height: 2;
  }
`;

const Hamburger = styled.div`
  height: 30px;
  width: 30px;
  margin-top: 25px;
  margin-right: 20px;
  display: none;
  cursor: pointer;

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    display: block;
  }
`;

const NavItem = styled.li`
  position: relative;
`;

const NavItemLink = styled.a`
  color: #FFFFFF;
  margin: 0 15px 0 33px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &.activeNav {
    color: #E64C3C;
    font-weight: 300;

  }
`;

const navItems = [{
  label: 'Home',
  path: ['/'],
  actives: ['/plan', '/sign-up', '/products', '/']
}, {
  label: 'Pricing',
  path: ['/pricing'],
  actives: ['/pricing']
}, {
  label: 'Sign In',
  path: ['/login'],
  actives: ['/login', '/complete']
}, {
  label: 'About',
  path: ['/about'],
  actives: ['/about', '/privacy-policy']
}, {
  label: 'Contact',
  path: ['/contact'],
  actives: ['/contact']
}];
for (let i = 0; i < navItems.length; i++) {
  
  if(navItems[i].actives.includes(window.location.pathname)){
    navItems[i].activeClass = "activeNav";
  }
  
}
class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileNav: false,
    }
  }

  handleMobileClick = () => {
    this.setState({ showMobileNav: !this.state.showMobileNav });
  }

  render() {

    return (
      <NavBar>
        <NavContainer>
          <Nav>
            <StyledNavA href='/'>
              <Logo src={remilyLogo} alt="Remily logo" />
            </StyledNavA>
            <NavbarNav>
              {navItems.map((item, idx) => {
                return (
                  <NavItem id={`navItemId${idx}`} key={`navItem${idx}`}>
                    <NavItemLink href={item.path[0]}  className={item.activeClass || ''}>{item.label}</NavItemLink>
                  </NavItem>
                )
              })}
            </NavbarNav>

            <MobileNav showMobileNav={this.state.showMobileNav}>
              <Hamburger onClick={this.handleMobileClick}>
                <img src={hamburgerImg} alt="Menu Icon" />
              </Hamburger>

              <MobileNavWrapper>
                {navItems.map((item, idx) => {
                  return (
                    <MobileItem key={`MobileItem${idx}`}>
                      <a href={item.path}>{item.label}</a>
                    </MobileItem>
                  )
                })}
              </MobileNavWrapper>
            </MobileNav>
          </Nav>
        </NavContainer>
      </NavBar>
    )
  }
}

export default Navbar;