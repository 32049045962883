import React, { Component } from 'react';
import styled from 'styled-components';

import Navbar from '../components/Navbar';
import Button from '../components/Button';
import theLogo from '../assets/img/remily-logo-circle.svg';

const ContactWrapper = styled.div`
    width: 100%;
`;

const ContactContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 25px;
`;

const TopLogo = styled.img`
    margin: auto;
    width: 150px;
`;
const InfoWrapper = styled.div`
    max-width: 531px;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    & h1 {
      color: #E64C3C;
      font-size: 30px;
      font-weight: 300;
      margin: 0 0 10px;
    }
    & p {
      color: black;
      margin: 0 0 40px;
    }
`;

const ThinInputsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    & input {
      width: 48%;
    }
`;
const StyledInput = styled.input`
    height: 40px;
    border: none;
    padding: 10px;
    margin-bottom: 16px;
    background: #D0D1D1;
    width: 100%;
    &::placeholder{
      color: #9D9D9D;
      font-size: 14px;
    }
`;

const LargeTextArea = styled.textarea`
    margin: 0;
    border: 1px solid #CDCDCD;
    padding: 5px;
    background: #D0D1D1;
    width: 100%;
    padding: 10px;
    margin: 9px 0 27px;
    resize: none;
    &::placeholder{
      color: #9D9D9D;
      font-size: 14px;
    }
`;

const StyledButton = styled(Button)`
    width: 191px;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
`;


class Contact extends Component {
  state = {
    email: '',
    password: '',
    signUpBool: false
  };
  handleChange = (event, key) => {
    const { value } = event.target;
    this.setState({
      [key]: value,
    });
  };

  render() {
    return (
      <ContactWrapper>
        <Navbar></Navbar>
        <ContactContentWrapper>
          <TopLogo src={theLogo} alt="Remily Logo"/>
          <InfoWrapper>
            <h1>Contact Us</h1>
            <p>Please fill out the form and we will be in contact as soon as possible.</p>
            <ThinInputsWrapper>
              <StyledInput placeholder="First name"></StyledInput>
              <StyledInput placeholder="Last name"></StyledInput>
            </ThinInputsWrapper>
            <StyledInput placeholder="Email"></StyledInput>
            <StyledInput placeholder="Phone Number"></StyledInput>
            <LargeTextArea placeholder="Message" rows="6"></LargeTextArea>
            <StyledButton>Submit</StyledButton>
          </InfoWrapper>
        </ContactContentWrapper>
      </ContactWrapper>
    );
  }
}

export default Contact
