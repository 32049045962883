import React from 'react';
import { connect } from 'react-redux';
import { getCollection} from '../actions/apiActions';
import { componantDispatcher } from '../actions/apiActions'

import styled, { css } from 'styled-components';
import { Header, Icon } from 'semantic-ui-react';
import PhotoImage from '../components/PhotoImage';

import { apiUserLogin } from '../actions/apiActions'

const isEven = i => i % 2 === 0;
const maxWidth = '880px';


/* Meant for vertical layout, this keeps photos on both sides
   justified towards the middle of the grid. This may not be needed! */
const justifyTowardsCenterMixin = css`
  justify-self: ${props => (isEven(props.index) ? 'end' : 'start')};
  margin-left: ${props => (!isEven(props.index) ? '10px' : 'unset')};
  margin-right: ${props => (isEven(props.index) ? '10px' : 'unset')};

  @media (max-width: ${maxWidth}) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 80px;
  }
`;

const photoDotMixin = css`
  content: '';
  position: absolute;
  display: block;
  width: 25px;
  height: 25px;
  border: 2px solid #999;
  border-radius: 50%;
  background: #fff;

  @media (max-width: ${maxWidth}) {
    display: none;
  }
`;

const verticalLayoutPhotoDot = css`
  ${photoDotMixin};
  top: calc(50% - 25px);
  right: ${props => (isEven(props.index) ? '-44px' : '')};
  left: ${props => (isEven(props.index) ? '' : '-43px')};
`;

const horizontalLayoutPhotoDot = css`
  ${photoDotMixin};
  bottom: ${props => (isEven(props.index) ? '-44px' : '')};
  top: ${props => (isEven(props.index) ? '' : '-43px')};
`;

const TimelineWrapper = styled.div`
  overflow: auto;
  width: 100%;
  height: 98.5%;
  @media (max-width: ${maxWidth}) {
    margin: 0;
    padding: 20px; 
  }
`;

const TimelinePhotoWrapper = styled.div`
  display: flex;
  grid-row: ${props => (props.vertical ? 'span 2' : '')};
  grid-column: ${props => (!props.vertical ? 'span 2' : '')};
  min-width: 60%;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  position: relative;
  ${props => (props.vertical ? justifyTowardsCenterMixin : null)};
  ${props => (!props.vertical ? 'margin: 10px 0;' : null)};
  &:after {
    ${props => (props.vertical ? verticalLayoutPhotoDot : horizontalLayoutPhotoDot)};
  }

  @media (max-width: ${maxWidth}) {
  //  max-width: 60%; 
  }
`;

const straightLineMixin = css`
  content: '';
  position: absolute;
  background: #999;
  border-radius: 2px;

  @media (max-width: ${maxWidth}) {
   display: none; 
  }
`;

const verticalLineMixin = css`
  ${straightLineMixin};
  width: 4px;
  // height: 100%;
  left: calc(50% - 2px);
  top: 0;
  bottom: 0;
`;

const horizontalLineMixin = css`
  ${straightLineMixin};
  height: 4px;
  width: 100%;
  top: calc(50% - 2px);
  left: 0;
`;

const verticalGridMixin = css`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 125px);
  grid-column-gap: 40px;
  grid-auto-flow: row;
  grid-auto-rows: 125px;
`;

const horizontalGridMixin = css`
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 40px;
  grid-auto-flow: column;
  grid-auto-columns: 250px;
`;

const PhotoTimelineGrid = styled.div`
  display: inline-grid;
  margin: 0 auto;
  margin-top: 16px;
  position: relative;
  min-width: 100%;
  ${props => (props.vertical ? verticalGridMixin : horizontalGridMixin)};

  &:before {
    ${props => (props.vertical ? verticalLineMixin : horizontalLineMixin)};
  }

  @media (max-width: ${maxWidth}) {
    display: block;
  }
`;

const FloatingCornerIcon = styled(Icon)`
  position: sticky;
  top: 20px;
  left: 20px;
  z-index: 1000;

  @media (max-width: ${maxWidth}) {
    display: none !important;
  }
`;

const TimelinePhoto = props => (
  <TimelinePhotoWrapper {...props}>
    <PhotoImage src={props.url} />
    <TimelineWrapper p={2}>
      <Header>{props.name}</Header>
      <p>{props.summary}</p>
    </TimelineWrapper>
  </TimelinePhotoWrapper>
);

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vertical: true
    }
    this.handleScroll = this.handleScroll.bind(this);
  }

  toggleOrientation = () => this.setState({ vertical: !this.state.vertical });
    
  
  componentDidMount() {
      document.getElementById("timelineWrapper").addEventListener('scroll', this.handleScroll);
      this.props.getCollection(this.props.jwt, this.props.collection.data.apikey, 1, 5)
      this.props.componantDispatcher({type: "UNSET_COLLECTION", payload: {}})
  }

  componentWillUnmount() {
      document.getElementById("timelineWrapper").removeEventListener('scroll', this.handleScroll);
  }
  componentWillReceiveProps(){
      this.isLazyloading = false
  }
  isLazyloading = false
  handleScroll = (event) => {
      let scrollDiv = event.target;
      let offset = 150;
      let scrollTop = event.target.scrollTop;
      //console.log(scrollDiv.scrollHeight - scrollTop - scrollDiv.clientHeight);
      
      if(!this.isLazyloading){
          if(this.props.collection.data.page_details.current_page < this.props.collection.data.page_details.total_pages){
              if(scrollDiv.scrollHeight - scrollTop - offset <= scrollDiv.clientHeight){
                  this.isLazyloading = true
                  this.props.getCollection(this.props.jwt, this.props.collection.data.apikey, this.props.collection.data.page_details.current_page + 1, 4)
              };
          }
      }
  }

  render() {
    return (
      <TimelineWrapper m={2} id="timelineWrapper">
        {this.props.collection && <FloatingCornerIcon
          circular
          inverted
          size="large"
          color="teal"
          name={this.state.vertical ? 'resize vertical' : 'resize horizontal'}
          onClick={this.toggleOrientation}
        /> }{this.props.collection && 
        <Header size="large" textAlign="center">
          {this.props.collection.data.name}
        </Header> }{this.props.collection && 
        <PhotoTimelineGrid vertical={this.state.vertical}>
          {
            this.props.collection.data.images.map((photo, i) => [
              <TimelinePhoto key={photo.name + i} index={i} vertical={this.state.vertical} {...photo} />,
              // Insert empty dom node to take space in the grid in order to create the staggered timeline effect
              <div key={i} />
            ])}
        </PhotoTimelineGrid>}
      </TimelineWrapper>
    );
  }
}
const mapStateToProps = state => {
  let returnedProps = {};
  if(state.apiReducer.collection){
    returnedProps.collection = state.apiReducer.collection;
  }; 
  if(state.apiReducer && state.apiReducer.jwt){
    returnedProps.jwt = state.apiReducer.jwt;
  };  
  return {...returnedProps};
}

export default connect(mapStateToProps, {componantDispatcher, getCollection, apiUserLogin })(Timeline);
