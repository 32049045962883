import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import fileIconSrc from '../assets/img/opened-folder.png';
import SearchCollections from './SearchCollections';
import AddCollectionRow from '../containers/AddCollectionRow';

const CollectionListWrap = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  @media (max-width: 992px) {
    height: 190px;
  }
`;

const Collection = styled.li`
  display: flex;
  position: relative;
  border-top: 1px solid #707070;
  width: 100%;
  min-height: 66px;
  @media (max-width: 992px) {
    width: 170px;
    height: 61px;
    border-top: none;
  }
  &:hover div{
    color: #E64C3C;
    font-weight: 500;
  }
`;

const CollectionName = styled.div`
  color: #4E4E4E;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 27px 0 22px 22px;
  font-size: 16px;
  @media (max-width: 992px) {
    width: 170px;
    height: 61px;
    padding: 0;
  }
`;
const FileIconWrapper = styled.div`
  height: 50px;
  width: 71.43px;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-right: 14.6px;
`;
const FileIcon = styled.img`
  align-self: center;
  max-width: 100%;
  max-height: 100%;
`;

const Arrow = styled.div`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid ${props => props.isSelected ? "#E64C3C" : "#4E4E4E"};
  bottom: 10px;
  height: 0;
  position: absolute;
  right: 10px;
  transform: rotate(-45deg);
  width: 0;
`;

const ListAndAdd = styled.div`
    display: flex;
    flex-flow: column;
    flex: auto 1 auto;

    @media (max-width: 992px) {
      width: 100%;
      height: 100px;
      flex-flow: row;
      overflow-x: scroll;
    }
`;

const CollectionList = ({ collections, textSearch, onSearch, handleSearchEnter, handleSearchClear, setCollectionIndex, editObjSetter, getSpecificCollection, jwt, predictiveOptions, setCurrentCollection, editIconVar, currentCollectionApiKey, handleSetCurrentCollectionApiKey, imageUploadOverlayHandler, searchBool, ifSearchSearchReverser }) => {
  const collectionsListBuilder=()=>{
    return collections.map((collection, i) => {
      return (
        <Collection key={i} onClick={()=>{setCollectionIndex(i); getSpecificCollection(jwt, collection.apikey); setCurrentCollection(collection.apikey); handleSetCurrentCollectionApiKey(collection.apikey); ifSearchSearchReverser()}} >
          <CollectionName id={`${collection.apikey}-text`}>
            <FileIconWrapper>
              <FileIcon src={collection.thumnail_image && collection.thumnail_image !== "/images/thumb/missing.png" ? collection.thumnail_image : fileIconSrc} alt={collection.name} /> 
            </FileIconWrapper>
            {collection.name}
            {editIconVar === `${collection.apikey}` && 
              <Icon onClick={()=> editObjSetter(collection, "collection")} name="edit" size="large" color="grey" style={{marginLeft: '15%'}} />
            }
            <Arrow isSelected={currentCollectionApiKey === collection.apikey ? true : false}/>
          </CollectionName>
        </Collection>
      );
    })
  };
  return(
    <CollectionListWrap>
      <SearchCollections textSearch={textSearch} onSearch={onSearch} predictiveOptions={predictiveOptions} handleSearchEnter={handleSearchEnter} handleSearchClear={handleSearchClear} jwt={jwt} searchBool={searchBool} />
      <ListAndAdd id="actualCollectionList" >
        <AddCollectionRow imageUploadOverlayHandler={imageUploadOverlayHandler}/>
        { collections.length > 0 && collectionsListBuilder()}
      </ListAndAdd>
    </CollectionListWrap>
  )
};

export default CollectionList;
