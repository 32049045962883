import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCollection} from '../actions/apiActions';
import styled from 'styled-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
// import { Link } from 'react-router-dom';
// import { Icon } from 'semantic-ui-react';

import theLogo from '../assets/img/remily-logo-simbol.png';
import Button from '../components/Button2';
import Modal from '../components/Modal';
import CollectionPhoto from '../components/CollectionPhoto';
import ObjEditor from '../components/ObjEditor';
import DropzoneClickComponent from '../components/DropzoneClickComponent';
import TimeLineImage from '../assets/img/timeline.png';
import EditImage from '../assets/img/edit-icon.png';
import JournalImage from '../assets/img/journal.png';


const maxWidth = '880px';

const PhotoCollectionContainer = styled.div`
    background: #DBDBDB;   
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    max-width: 45%;
    @media (max-width: 992px) {
        max-width: 100%;
    }
    @media (min-width: 1280px) {
        width: 100%;
        max-width: calc(100% - 700px);
    }
`;
const PhotosWrapper = styled.div`
    max-height: 100%;
    padding: 15px 0px 15px 10px;
    overflow-y: scroll;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: 992px) {
        max-height: unset;
    }
`;

const CollectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 37px 30px 15px;

    @media (max-width: ${maxWidth}) {
        padding-top: 20px;
        font-size: 16px;
    }
    & #viewTimelineLink{
        color: gray;
    }
`;
const HeaderLinks = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    &:hover{
        cursor: pointer;
    }
    & p {
        font-size: 18px;
        color: #3B97D3;
        font-weight: 600;
        margin: 0;
        padding-right: 7px;
        align-self: center;
    }

    &:hover span {
        visibility: visible;
    }
`;
const HeaderIcons = styled.img`
    align-self: center;
`;
const ToolTip = styled.div`
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;

    & span {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        
        /* Position the tooltip */
        position: absolute;
        top: -23px;
        left: 105%;
    }
`;
const StyledCollectionPhoto = styled(CollectionPhoto)`
    align-self: center;
`;

// =========== Delete Confirm MODAL ===============
const StyledModal = styled(Modal)`
  & #modalInfoWrapper{
    padding: 62px 28px;
  }
  & #passwordResetSuccessLogo{
    margin-top: 40px;
  }
  & #usernameResetSuccessLogo{
    margin-top: 40px;
  }
  & #activatingYourAccountNowTitle{
    text-align: center;
    align-self: center;
  }
  & #activatingYourAccountNowButton{
    margin: auto;
  }
  
`;
const ModalButtonWrapper = styled.div`
    width: 410px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
`;
const ModalButton = styled(Button)`
    width: 191px;
    max-width: 45%;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 6px;
    background: #E64C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
`
const ModalContentTitle = styled.h3`
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin: 0 0 10px;
`;
const ModalImage = styled.img`
    margin: 0 0 25px;
`;
const SearchTitle = styled.h2`
    font-size: 30px;
    font-weight: 200;
    color: #E64C3C;
    border-top: 20px solid transparent;
`;
// =========== END Delete Confirm MODAL ===============
class CollectionDisplayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteFlag: false,
            editCollectionFlag: false,
        }   
        this.handleScroll = this.handleScroll.bind(this);

    }
    
  
    componentDidMount() {
        document.getElementById("photoCollection").addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.getElementById("photoCollection").removeEventListener('scroll', this.handleScroll);
    }
    componentWillReceiveProps(nextProps){
        this.isLazyloading = false
        //console.log("nextProps", nextProps);
        this.setState({
            ...this.state,
          })
    }
    isLazyloading = false
    handleScroll = (event) => {
        let scrollDiv = event.target;
        let offset = 150;
        let scrollTop = event.target.scrollTop;
        if(!this.isLazyloading){
            if(this.props.collection && this.props.collection.data && this.props.collection.data.page_details &&this.props.collection.data.page_details.current_page < this.props.collection.data.page_details.total_pages){
                if(scrollDiv.scrollHeight - scrollTop - offset <= scrollDiv.clientHeight){
                    this.isLazyloading = true
                    this.props.getCollection(this.props.jwt, this.props.collection.data.apikey, this.props.collection.data.page_details.current_page + 1, this.props.collection.data.page_details.requested)
                };
            }
        }
    }  
    render() { 
        return (
            <PhotoCollectionContainer id="photoCollection">
              { 
                this.props.collection.data.apikey && !this.props.searchBool &&
                <CollectionHeader>
                    <HeaderLinks id="viewTimelineLink">
                        {/* <Link to={`/app/timeline`} onClick={()=>this.props.setCollectionIndex(this.props.collection.dataIndex)}>
                        View Timeline
                        <HeaderIcons src={TimeLineImage}/>
                        </Link> */}
                        <ToolTip>
                            <span>This feature is coming soon</span>
                        </ToolTip>
                        <p>View Timeline</p>
                        <HeaderIcons src={TimeLineImage}/>
                    </HeaderLinks>

                    <HeaderLinks onClick={ ()=>this.props.editObjSetter({name: "", summary: "", apikey: "1", journalEntry: ""}, "journal") }>
                        <p>Journal Entry</p>
                        <HeaderIcons src={JournalImage} name="book" size="large" color="brown" 
                         />
                    </HeaderLinks>

                    <HeaderLinks onClick={()=>{
                            this.props.editObjSetter(this.props.collection.data, "collection")
                            this.setState({editCollectionFlag: true})
                        }}>
                        <p>Edit</p>
                        <HeaderIcons src={EditImage} name="edit" width="20px" color="red"  />
                    </HeaderLinks>
                      
                </CollectionHeader>
              }
              {
                  this.props.searchBool && 
                  <SearchTitle style={{"width": "100%", "textAlign": "center"}}>Images Results</SearchTitle>
              }
              {
                this.props.collection.data.name &&
                <PhotosWrapper>
                    {this.props.collection.data.images.map((photo, i) =>{ if(photo.collections.length>0){return (
                        <StyledCollectionPhoto src={photo.medium_url ? photo.medium_url : photo.url} name={photo.name} key={photo.name + i} editObjSetter={this.props.editObjSetter} image={photo} />
                    )} return false})}
                    { !this.props.searchBool && <DropzoneClickComponent imageUploadOverlayHandler={this.props.imageUploadOverlayHandler} />}
                </PhotosWrapper>
              }
                <ReactCSSTransitionGroup
                    transitionName='example'
                    transitionAppear={true}
                    transitionEnter={true}
                    transitionLeave={true}
                    transitionEnterTimeout={500}
                    transitionAppearTimeout={500}
                    transitionLeaveTimeout={500}
                >
                {this.props.editObj.apikey && 
                    <ObjEditor passedObj={this.props.editObj} editObjType={this.props.editObjType} editObjSetter={this.props.editObjSetter} handleEditObjChange={this.props.handleEditObjChange} />
                }
                {this.state.editCollectionFlag && 
                    <ObjEditor passedObj={this.props.editObj} editObjType={"collection"} editObjSetter={this.props.editObjSetter} handleEditObjChange={this.props.handleEditObjChange} />
                }
                </ReactCSSTransitionGroup>
                
                {this.state.deleteFlag && 
                    <StyledModal id="deleteModal">
                    <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" />
                    <ModalContentTitle>Are you sure you want to delete this?</ModalContentTitle>
                    <ModalButtonWrapper>
                        <ModalButton onClick={()=>{this.setState({deleteFlag: !this.state.deleteFlag})}} text="Cancel" />
                        <ModalButton onClick={()=>{this.props.handleDelete(this.props.collection.data.name); this.setState({deleteFlag: !this.state.deleteFlag})}} text="Delete" />
                    </ModalButtonWrapper>
                    </StyledModal>
                }
            </PhotoCollectionContainer>
          );
    }
}
const mapStateToProps = state => {
    //console.log(state);
    let returnedProps = {};
    if(state.apiReducer.searchBool){
            returnedProps.searchBool = state.apiReducer.searchBool;
        if(state.apiReducer && state.apiReducer.searchCollection){
            returnedProps.collection = state.apiReducer.searchCollection;
        }; 
    }
    if(!state.apiReducer.searchBool){
        if(state.apiReducer && state.apiReducer.collection){
        returnedProps.collection = state.apiReducer.collection;
        };
    }
    if(state.apiReducer && state.apiReducer.jwt){
      returnedProps.jwt = state.apiReducer.jwt;
    };  
    return {...returnedProps};
  }
  
export default connect(mapStateToProps, {getCollection}) (CollectionDisplayer);