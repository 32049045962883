import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './ProtectedLayout.css';

import Account from '../../containers/Account';
import Timeline from '../../containers/Timeline';
import CollectionManager from '../../containers/CollectionManager';
import Family from '../../containers/Family';

class ProtectedLayout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.object, // eslint-disable-line
  };

  static defaultProps = {
    error: {},
  };

  state = {};

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/account`} component={Account} />
        <Route path={`${match.path}/photos`} component={CollectionManager} />
        <Route path={`${match.path}/timeline`} component={Timeline} />
        <Route path={`${match.path}/family`} component={Family} />
        <Redirect to={`${match.url}`} />
      </Switch>
    );
  }
}

export default connect(state => ({
  user: state.user,
}))(ProtectedLayout);
