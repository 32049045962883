import React from 'react';
import styled from 'styled-components';
// import PhotoCommentIcon from '../assets/img/photo-comment.png';
import JournalBackground from '../assets/img/journal-background.png';

// import { colors, typography } from '../constants/StyleConstants';

const Photo = styled.div`
  width: 165px;
  margin: 0 0 10px 15px;
  background-color: #00000050;
    &:hover{
        cursor: pointer;
    }
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 110px;
  position: relative;
`;

const Image = styled.img`
  align-self: center;
  height: auto;
  width: 100%;
`;
// const CommentIcon = styled.img`
//   position: absolute;
//   bottom: 5px;
//   right: 6px;
// `;

// const ImageName= styled.p`
//   color: ${colors.gray2};
//   margin-top: 5px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   ${typography.caption};
// `;

const JournalWrapper = styled.div`
  width: 100%;
  height: 110px;
  padding: 6px 9px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url(${JournalBackground});
  background-size: cover;
`;
const JournalTitle = styled.h1`
  color: #E64C3C;
  font-size: 12px;
  font-weight: 800;
  margin: 0;
  min-height: unset;
`;

const JournalSummary = styled.p`
  color: #000000;
  font-size: 10px;
  font-weight: 400;
  padding-bottom: 10px;
`;

const CollectionPhoto = ({className, src, name, image, editObjSetter}) => (
  <Photo className={className} onClick={()=>editObjSetter(image, src?"image":"journal")}>
    {src ? <ImageWrapper>
      <Image src={src} /> 
      {/* <CommentIcon src={PhotoCommentIcon}/> */}
    </ImageWrapper>
    : 
    <JournalWrapper>
      <JournalTitle>{name}</JournalTitle>
      <JournalSummary>{image.summary ? image.summary.substring(0, 146)+" ...":""}</JournalSummary>
    </JournalWrapper>
    }
    {/* {name && <ImageName id={`${image.apikey}-text`}>{name}</ImageName>} */}
  </Photo>
);

export default CollectionPhoto;