import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom'

const OverlayBackground = styled.div`
    position: absolute;
    margin: 0;
    height: calc( 100% - 48px);
    width: 100%;
    background: #ffffff95;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ObjEditorWrapper = styled.div`
    max-width: 90vw;
    max-height: 90vh;
    padding: 30px;
    background: #FFF; 
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    position: relative;
    z-index: 99;
    @media (max-width: 992px) {
      flex-flow: column;
    }
`;
class Modal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        redirect: false,
      };
      this.redirectToCurrentPath = this.redirectToCurrentPath.bind(this);
    }
    
    redirectToCurrentPath = () => {
        return <Redirect to={`${window.location.pathname}`} />
        // this.props.history.push(`${window.location.pathname}`);
    }
    handelRedirect(){
        this.setState({redirect:true})
    }
    childHandle = (e) => {
        e.stopPropagation();
    }
    render() { 
        return ( 
            <OverlayBackground onClick={this.props.noExit ? ()=>{} : this.props.passedHandle ? ()=>{ this.props.passedHandle(this.props.passedHandleArg) } : ()=> this.handelRedirect()} className={this.props.className}>
               <ObjEditorWrapper onClick={(e)=>this.childHandle(e)} id="modalInfoWrapper">
               {this.state.redirect && this.redirectToCurrentPath()}
                    {this.props.children}
               </ObjEditorWrapper>
            </OverlayBackground> 
        );
    }
}
 
export default Modal;