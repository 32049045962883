import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { post } from 'axios';
import EXIF from 'exif-js';

import Button from './Button2';
import Dropdown from './DropdownUniversalStyled';
import LoadingGif from '../assets/img/Loading.gif';
import { getCollection } from '../actions/apiActions';


const OverlayBackground = styled.div`
    background: transparent; 
    position: fixed;
    top: 0;
    bottom: 48px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 992px) {
      flex-flow: column;
    }
    &.example-appear {
      transform: translateX(100%);
    }
    &.example-appear-active {
      transform: translateX(0px);
      transition: transform 500ms ease-out;
    }
    &.example-enter {
      transform: translateX(100%);
    }
    &.example-enter.example-enter-active {
      transform: translateX(0px);
      transition: transform 500ms ease-out;
    }
    &.example-leave {
      transform: translateX(0px);
    }
    &.example-leave.example-leave-active {
      transform: translateX(100%);
      transition: transform 500ms ease-in;
    }
`;
const ObjEditorWrapper = styled.div`
    width: calc(61% - 73px);
    height: 100%;
    max-height: 100%;
    padding: 54px 27px 31px 47px;
    background: #FFFFFF; 
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-self: center;
    @media (max-width: 1230px) {
      padding: 30px;
    }
    @media (max-width: 992px) {
      width: 100%;
      height: calc(100% - 265px);
    }
`;
const Title = styled.h1`
    color: #E64C3C;
    font-size: 28px;
    letter-spacing: -.015em;
    line-height: 25px;
    font-weight: 300;
    margin: 0;
`;
const ContentWrapper = styled.div`
    max-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: space-evenly;
    align-self: center;
`;
const PhotosList = styled.div`
    padding: 0 15px 0 0;
    margin: 28px 0;
    max-height: 100%;
    height: 100%;
    overflow-y: scroll;
`;
const PhotoAndTitleWrapper = styled.div`
    width: 250px;
    max-width: calc(50% - 8px);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;
const PhotoWrapper = styled.div`
    max-width: 250px;
    width: 100%;
    height: calc(100% - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    overflow: hidden;
`;
const PhotosListItemImage = styled.img`
    align-self: center;
    max-height: 166.5px;
    height: auto;
    max-width: 100%;
    width: auto;
`;
const PhotosListItemName = styled.input`
    height: 40px;
    border: 1px solid #CDCDCD;
    padding: 10px;
    background: #D0D1D1;
    width: 100%;
    &::placeholder{
        color: #9D9D9D;
        font-size: 14px;
    }
`;
const PhotoDataWrapper = styled.div`
    height: 219px;
    width: calc(100% - 266px);
    min-width: calc(50% - 8px);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: space-between;
    align-self: center;
    
    @media (max-width: 768px) {
        margin-left: 15px;
    }
`;
const LodationAndDateWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: space-between;
    align-self: center;
`;
const PhotosListItemLocation = styled.input`
    height: 40px;
    border: 1px solid #CDCDCD;
    padding: 10px;
    background: #D0D1D1;
    width: calc(50% - 5px);
    &::placeholder{
        color: #9D9D9D;
        font-size: 14px;
    }
`;
const PhotosListItemDate = styled.input`
    height: 40px;
    border: 1px solid #CDCDCD;
    padding: 10px;
    background: #D0D1D1;
    width: calc(50% - 5px);
    &::placeholder{
        color: #9D9D9D;
        font-size: 14px;
    }
`;
const PhotosListItem = styled.div`
    display: flex;
    flex-flow: row;
    padding: 10px 0 25px;
    text-align: center;
    justify-content: space-between;
    align-content: center;
    &:focus-within * > input{ 
        background-color: #B0B0B0;
        &::placeholder {
            color: #9D9D9D;
        }
    }
    &:focus-within * > textarea{ 
        background-color: #B0B0B0;
        &::placeholder {
            color: #9D9D9D;
        }
    }
`;
const PhotosListItemDesc = styled.textarea`
    margin: 0;
    border: 1px solid #CDCDCD;
    background: #D0D1D1;
    width: 100%;
    height: 112px;
    max-height: 140px;
    overflow-y: scroll;
    padding: 13px;
    resize: none;
    &::placeholder{
        color: #9D9D9D;
        font-size: 14px;
    }
`;
const StyledDropdown = styled(Dropdown)`
      height: 40px;
      width: 100%;
      max-width: 100%;
      margin: 0;
    & button{   
      height: 100%;  
      width: 100%;
      margin: 0;
    }
    & #dropdownMenu{
      width: 100%;
      margin: 0;
    }
`;
const UploadingPhotoInProcessWrapper = styled.div`
    position: absolute;
    z-index: 20;
`;
const UploadingPhotoInProcess = styled.img`
    height: 60px;
`;
const ButtonWrapper = styled.div`
    background: #FFFFFF;
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
`;
const StyledButton = styled(Button)`
    width: 191px;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-left: 25px;
    background-color: #E64C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
`;
class ImageUploadModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            stateUpdaterBool: false,
            imagesToUpload: props.imagesToUpload,
            blurerFlag: true,
            newApiKey: "",
        };
        this.uploadPhotosHandler = this.uploadPhotosHandler.bind(this);
        this.preImageUploadProccesing = this.preImageUploadProccesing.bind(this);
        this.handleDropdown = this.handleDropdown.bind(this);
    }
     
    childHandle = (e) => {
        e.stopPropagation();
    }

    handleDropdown = (newApiKey, index) => {
      console.log("index", index);
      console.log("newApiKey", newApiKey);
      let dropDownElement = document.getElementById(`uploadCollectionInput-${index}`)
      dropDownElement.setAttribute("ApiKeyValueHolder", newApiKey || '')
      this.setState({newApiKey});
    }

    uploadPhotosHandler=(counter)=>{
        let submitButton = document.getElementById("uploadSubmitButton");
        console.log(submitButton);
        
        if(submitButton){
            submitButton.setAttribute("style", "pointer-events: none; background-color: #aa1e0f");
        }
        let allUploadImageElements = document.getElementsByClassName("preUploadImage")
        if(this.state.blurerFlag){
            for (let i = 0; i < allUploadImageElements.length; i++) {
                allUploadImageElements[i].style.filter= "blur(4px)";
                allUploadImageElements[i].style.WebkitFilter= "blur(4px)";
            }
            this.setState({blurerFlag: false})
        }
        if(!this.state.imagesToUpload[counter]){ 
            this.setState({isUploading: false})
            this.props.imageUploadOverlayHandler(false, []); 
            return
        }
        if(this.state.imagesToUpload[counter]){
            this.fileUpload(counter)
        }
    }
    
    fileUpload(counter){
        let formData = new FormData();
        let file = this.state.imagesToUpload[counter];
        let inputName = document.getElementById(`uploadName-${counter}`).value;
        let inputSummary = document.getElementById(`uploadSummary-${counter}`).value;
        let inputDate = document.getElementById(`uploadDate-${counter}`).value;
        let inputLocation = document.getElementById(`uploadLocation-${counter}`).value;
        let theSetCollextionApiKey = document.getElementById(`uploadCollectionInput-${counter}`);
       
	console.log('image before post:', file);
	console.log('theSetCollextionApiKey', theSetCollextionApiKey);
        formData.append('image',file);
        formData.append('name', file.name);
        formData.append('collection_id', theSetCollextionApiKey.getAttribute("ApiKeyValueHolder") || this.props.collection.apikey);
        if(inputLocation){
            formData.append('image_date_taken', inputDate);
        }
        if(inputLocation){
            formData.append('image_date_taken', inputLocation);
        }
        formData.append('summary', inputSummary || '');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.jwt}`
            },
        }

        console.log("headerData", this.props.jwt);
        
        return  post("http://remily-api.pxpqa.com/images", formData,config).then((response)=>{
            if(document.getElementById(`UploadingPhotoInProcess-${counter}`)){
                let elementInProcess = document.getElementById(`UploadingPhotoInProcess-${counter}`)
                elementInProcess.setAttribute("style", "display: none;")
                let imageElement = document.getElementById("imageUpload"+counter)
                imageElement.style.filter= "none";
                imageElement.style.WebkitFilter= "none";
            }
            counter++
            this.uploadPhotosHandler(counter);
        }).catch(error => {
            console.log(error);
            alert(`
            Your Image " ${formData.name} " did not upload. Please Try again.
            `)
            if(document.getElementById(`UploadingPhotoInProcess-${counter}`)){
                let elementInProcess = document.getElementById(`UploadingPhotoInProcess-${counter}`)
                elementInProcess.setAttribute("style", "display: none;")
                let imageElement = document.getElementById("imageUpload"+counter)
                imageElement.style.filter= "none";
                imageElement.style.WebkitFilter= "none";
            }
            counter++
            this.uploadPhotosHandler(counter);
        });
    }
    preImageUploadProccesing(){
        let preuploadImagesElementsArray = document.getElementsByClassName("preUploadImage")
        for (let index = 0; index < preuploadImagesElementsArray.length; index++) {
            if(document.getElementById(`imageUpload${index}`)){
                let img = document.getElementById(`imageUpload${index}`);
                let imgWrapper = document.getElementById(`imageUploadImageWrapper-${index}`);
                EXIF.getData(img, function(){
                    let allMetaData = EXIF.getAllTags(this);
                    console.log(allMetaData);
                    let orientation = EXIF.getTag(this, "Orientation");
                    if(orientation){
                        if(orientation === 6){
                            img.setAttribute("style", `transform: rotate(90deg); max-width: 166.5px; width: auto; max-height: 100%; height: auto;`);
                            imgWrapper.setAttribute("style", `height: 166.5px;`);
                        }
                        if(orientation === 8){
                            img.setAttribute("style", `transform: rotate(270deg); max-width: 166.5px; width: auto; max-height: 100%; height: auto;`);
                        }
                        if(orientation === 3){
                            img.setAttribute("style", `transform: rotate(180deg);`);
                        }
                    }
                    if(allMetaData && allMetaData.DateTimeOriginal){
                        let dateElement = document.getElementById(`uploadDate-${index}`)
                        let dateString = allMetaData.DateTimeOriginal.toString()
                        let newString = dateString.slice(0,4)+"-"+dateString.slice(5,7)+"-"+dateString.slice(8,11);
                        dateElement.value = newString
                    }
                    if(allMetaData && allMetaData.GPSLatitude && allMetaData.GPSLatitude[0]){
                        let locationElement = document.getElementById(`uploadLocation-${index}`)
                        let firstNum = allMetaData.GPSLatitude[0].numerator / allMetaData.GPSLatitude[0].denominator
                        let secondNum = allMetaData.GPSLatitude[1].numerator / allMetaData.GPSLatitude[1].denominator
                        let thirdNum = (allMetaData.GPSLatitude[2].numerator / allMetaData.GPSLatitude[2].denominator).toFixed(2)
                        let firstCord = allMetaData.GPSLatitudeRef
                        let fourthNum = allMetaData.GPSLongitude[0].numerator / allMetaData.GPSLongitude[0].denominator
                        let fifthNum = allMetaData.GPSLongitude[1].numerator / allMetaData.GPSLongitude[1].denominator
                        let sixthNum = (allMetaData.GPSLongitude[2].numerator / allMetaData.GPSLongitude[2].denominator).toFixed(2)
                        let secondCord = allMetaData.GPSLongitudeRef
                        let location = `${firstNum} deg ${secondNum}' ${firstCord} ${thirdNum}, ${fourthNum} deg ${fifthNum}' ${secondCord} ${sixthNum}`
                        locationElement.value = location
                    }
                    return true;
                });
            }
        }
    }
    onNextFrame(callback) {
        setTimeout(function () {
            window.requestAnimationFrame(callback)
        }, 500)
    }
    componentDidMount(){
        this.onNextFrame( this.preImageUploadProccesing);
    }
  render() { 
    return ( 
      <OverlayBackground onClick={()=>this.props.imageUploadOverlayHandler(false, [])}>
        <ObjEditorWrapper onClick={(e)=>this.childHandle(e)} id="ObjEditorUploadWrapper">
            <Title>Add Photos and Info</Title>
            <ContentWrapper>
                {this.props.imagesToUpload.length > 0 && 
                    <PhotosList>
                        {this.props.imagesToUpload.map((image, index)=>{
                            return (
                                <PhotosListItem key={`photoListItem-${index}`}>
                                    <PhotoAndTitleWrapper>
                                        <PhotoWrapper id={`imageUploadImageWrapper-${index}`}>
                                            <PhotosListItemImage id={`imageUpload${index}`} src={image.preview} className={"preUploadImage"} style={{}} />
                                            
                                            {this.state.isUploading &&
                                                <UploadingPhotoInProcessWrapper id={`UploadingPhotoInProcess-${index}`}>
                                                    <UploadingPhotoInProcess src={LoadingGif}/>
                                                </UploadingPhotoInProcessWrapper>
                                            }
                                        </PhotoWrapper>
                                        <PhotosListItemName id={`uploadName-${index}`} placeholder={"Title"}></PhotosListItemName>
                                    </PhotoAndTitleWrapper>
                                    <PhotoDataWrapper>
                                        <PhotosListItemDesc id={`uploadSummary-${index}`} placeholder={"Tell us about this photo"} rows="6"></PhotosListItemDesc>
                                        <LodationAndDateWrapper>
                                            <PhotosListItemLocation id={`uploadLocation-${index}`} placeholder={"Location"}></PhotosListItemLocation>
                                            <PhotosListItemDate id={`uploadDate-${index}`} placeholder={"Date"}></PhotosListItemDate>
                                        </LodationAndDateWrapper>
                                        <StyledDropdown id={`uploadCollectionInput-${index}`} items={this.props.collections || {collections: []}} selectedItem={this.props.collection || {name:""}} onChange={this.handleDropdown} ApiKeyValueHolder={''} index={index} />
                                    </PhotoDataWrapper>
                                </PhotosListItem>
                            )
                        })}
                    </PhotosList>
                }
            </ContentWrapper>
            <ButtonWrapper>
                <StyledButton onClick={()=>{this.props.imageUploadOverlayHandler(false, [])}} text="Cancel"/>
                <StyledButton id="uploadSubmitButton" onClick={()=>{this.setState({isUploading: true}); this.uploadPhotosHandler(0)}} style={{}} text="Upload"/>
            </ButtonWrapper>
        </ObjEditorWrapper>
      </OverlayBackground>
    );
  }
}
 
const mapStateToProps = state => {
    let returnedProps = {};
    if(state.apiReducer.searchBool){
        returnedProps.searchBool = state.apiReducer.searchBool;
        if(state.apiReducer && state.apiReducer.searchCollections && state.apiReducer.searchCollections.data.collections){
            returnedProps.collections = state.apiReducer.searchCollections;
        };
        if(state.apiReducer && state.apiReducer.searchCollection){
            returnedProps.collection = state.apiReducer.searchCollection;
        }; 
    }
    if(!state.apiReducer.searchBool){
        if(state.apiReducer && state.apiReducer.collections && state.apiReducer.collections.data.collections){
            returnedProps.collections = state.apiReducer.collections;
        };
        if(state.apiReducer.collection){
            returnedProps.collection = state.apiReducer.collection.data;
        };
    }
    if(state.apiReducer.jwt){
      returnedProps.jwt = state.apiReducer.jwt;
    };
    return {...returnedProps};
}
 
export default connect(mapStateToProps, {getCollection}) (ImageUploadModal);
