const initialState = {
  isLoggedIn: false,
  data: {},
  loginError: {},
};

export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN_FAIL';
export const RECEIVE_USER = 'auth/RECEIVE_USER';
export const LOGOUT_USER = 'auth/LOGOUT_USER';

export default (state = initialState, action) => {
  switch (action.type) {
    case "RECEIVE_USER":
      return {
        ...state,
        isLoggedIn: true,
      };
    case "LOGOUT_USER":
      return {
        ...initialState,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        loginError: action.payload,
      };
    default:
      return state;
  }
};
