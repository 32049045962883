import React from 'react';
import styled from 'styled-components';

import Navbar from '../components/Navbar';
import image1 from '../assets/img/about-image-01.png';
import image2 from '../assets/img/about-image-02.png';
import image3 from '../assets/img/about-image-03.png';
import image4 from '../assets/img/image-icon.png';
import image5 from '../assets/img/money-outline.png';
import image6 from '../assets/img/share-with-family.png';

const FullWidthWrapper = styled.div`
  width: 100%;
`;
const Container = styled.div`
  margin: auto;
  display: flex;
  flex-flow: column;
  max-width: 1300px;
  width: 100%;
  & #aboutSection3 {
    justify-content: flex-end;
    & #AboutImageWrapper2 {
      flex: 1;
      align-self: center;
      & img {
        max-width: 320px;
      }
    }
  }
`;
const AboutSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media(max-width: 1199px){
    padding-top: 30px;
    flex-flow: column;
  }
`;
const AboutImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TopAboutImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const AboutImage = styled.img`
  align-self: flex-start;
  max-width: 100%;
`;

const AboutImage2 = styled.img`
  width: 76%;
  align-self: center;
`;
const SectionInfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 28px 27px;
  & h3{
    font-weight: 300;
    color: #E64C3C;
    text-align: left;
    font-size: 28px;
    margin-bottom: 28px;
  }

  & p{
    font-size: 16px;
    line-height: 22px;
    max-width: 689px;
    margin-bottom: 18px;
    color: #4E4E4E;
  }
  @media(max-width: 1199px){
    justify-content: center;
    align-items: center;
  }
`;
const LastSectionInfoWrapper= styled.div`
  flex:6;
  justify-content: space-between;
  padding: 30px 75px 85px;
  & h3{
    font-weight: 300;
    color: #E64C3C;
    text-align: left;
    font-size: 28px;
  }

  & p{
    padding: 0 40px 0 0;
    font-size: 16px;
    line-height: 22px;
    max-width: 1138px;
    letter-spacing: 1px;
  }
`;
const AboutSectionDark = styled.div`
  display: flex;
  background: #3B97D3;
  color: white;
  @media(max-width: 1199px){
    padding-top: 30px;
    flex-flow: column-reverse;
    & img {
      max-width: 360px;
    }
  }
  & h3{
    font-weight: 300;
    color: white;
  }
`;
const OtherFeaturesSection = styled.div`
  display: flex;
  background: #2C3E50;
  @media(max-width: 1199px){
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
`;
const OtherFeaturesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 30px 70px;
  & h3{
    font-weight: 300;
    color: #E64C3C;
    text-align: left;
    font-size: 28px;
  }

  & p{
    color: white;
    font-size: 16px;
    line-height: 22px;
    max-width: 689px;
  }
  @media(max-width: 1199px){
    justify-content: center;
    align-items: center;
    align-self: center;
  }
`;
const FeaturesGroupWrapper = styled.div`
  display: flex;
  flex-flow: row;
  background-color: #2C3E50;
  @media(max-width: 1199px){
    padding-top: 30px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
`;
const Features = styled.div`
  color: white;
  padding: 0px 30px 20px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  flex: 1;
  & img {
    align-self: center
  }
  & p {
    text-align: center;
    padding: 0;
  }
  & div {
    height: 150px;
    display: flex;
    justify-content: center;
  }
  @media(max-width: 1199px){
    justify-content: center;
    align-items: center;
    align-self: center;
  }
`;
const About = () => (
  <FullWidthWrapper>
    <Navbar />
    <Container>
      <AboutSection>
        <TopAboutImageWrapper>
          <AboutImage src={image1}/>
        </TopAboutImageWrapper>
        <SectionInfoWrapper>
          <h3>Your Legacy Matters</h3>
          <p>
            Remily exists because we noticed something important: people aren't preserving their family history. Instead we're storing pictures and videos in disparate places – from smart phones, laptops and cameras to social media sites. Then we‘re posting them sporadically and often with no family-related commentary.
          </p>
          <p>
            In thirty years we won‘t be able to view all those moments we captured and process the meaning they had for our families because existing social media sites are for the "now." Remily, on the other hand, is a cloud-based repository that will provide the scalability to store, create and relive a family legacy for centuries to come.
          </p>
        </SectionInfoWrapper>
      </AboutSection>

      <AboutSectionDark>
        <SectionInfoWrapper>
          <h3>What we do: Go way beyond a name</h3>
          <p>
            With a cloud-based, private social media site, you, your family and closest friends can easily store pictures, videos and narrative that will become accessible using any device at any time.
          </p>

          <p>
            The Remily repository will ensure that all of your family adventures, extraordinary moments and thoughts never get lost or left behind. Instead, they will be passed on to your immediate family members so they can read, learn and reflect on your family legacy—so simply knowing your great grandfather's name won't be enough anymore.
          </p>
        </SectionInfoWrapper>
        <AboutImageWrapper>
          <AboutImage2 src={image2}/>
        </AboutImageWrapper>
      </AboutSectionDark>

      <AboutSection id="aboutSection3">
        <AboutImageWrapper id="AboutImageWrapper2">
          <AboutImage2 src={image3}/>
        </AboutImageWrapper>
        <SectionInfoWrapper>
          <h3>How we do it: Organization, the Cloud and Reflecting</h3>
          <h2>Get Organized</h2>
          <p>
            Remily was designed to make uploading, accessing, storing and retrieving pictures and video fast and easy and that's why organization is key. So everything you post will be placed into specific folders and you'll be able to do a search according to names, years, titles, and occasions.
          </p>

          <p>
            For example, if you want to see a particular picture of your son's birthday you can type in his name and the word "birthday" and every picture organized by year will pop up. Choose the year, find the photo and you're done.
          </p>
          <h2>The cloud makes it possible</h2>
          <p>
            Our goal is to ensure that nothing about your journey in life gets lost and that's why it has to be cloud-based. This allows anyone in your Remily circle to access posts from anywhere in the world using any device while collaborating in real time. And there is no fear of losing what you and your circle create – ever. So you own the content while Remily preserves the quality and history of your family's story for centuries to come.
          </p>
          <h2>Bring it all together</h2>
          <p>
            We understand that while a picture may say a thousand words, they still don‘t tell the whole story. So another important part of Remily is the memoir feature which helps capture all of the small things.
          </p>
          <p>
            When you post a picture or a video you can include information that might otherwise be forgotten ten or twenty years from now. This reflecting and reliving feature brings your whole amazing Remily story together.
          </p>
        </SectionInfoWrapper>
      </AboutSection>

      <OtherFeaturesSection>
        <OtherFeaturesWrapper>
        <h3>Other features</h3>
          <FeaturesGroupWrapper>
            <Features>
              <div>
                <img src={image6} alt="review feature" width="129px" height="74px" />
              </div>
              <p>
                Control who can view your Remily content. If you want to include only your Remily circle then you can. But if you want to make your posts more public there is a feature that allows you to widen your audience.
              </p>
            </Features>
            <Features>
              <div>
                <img src={image5} alt="subscription feature" width="65px" height="106px" />
              </div>
              <p>
                Remily is subscription based with the options of a monthly or yearly fee.
              </p>
            </Features>
            <Features>
              <div>
                <img src={image4} alt="pictures feature" width="101px" height="68px" />
              </div>
              <p>
                Pictures can be printed, made into collages, Christmas cards, phone cases and digital frames. Videos can be downloaded and made into DVDs.
              </p>
            </Features>
            <Features>
              <div>
                <img src={image3} alt="backup feature" width="130px" height="65px" />
              </div>
              <p>
                Remily includes the capability to backup all content to a USB storage device.
              </p>
            </Features>
          </FeaturesGroupWrapper>
        </OtherFeaturesWrapper>
      </OtherFeaturesSection>

      <AboutSection>
        <LastSectionInfoWrapper>
          <h3>Join Us</h3>
          <p>
            We believe completely that our family and closest friends will always matter the most. Remily was created to celebrate that, but most importantly to preserve it. Get started today.
          </p>
        </LastSectionInfoWrapper>
      </AboutSection>
    </Container>
  </FullWidthWrapper>
)

export default About;
