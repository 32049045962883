import React, { Component } from 'react';
import { BrowserRouter , Route, Switch} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import getStore from '../store';
import styled from 'styled-components';

import ProtectedRoute from './ProtectedRoute';
import ProtectedLayout from '../components/protectedLayout/ProtectedLayout';
import About from './About';
import Contact from './Contact';
import Products from './Products';
import Complete from './Complete';
import Pricing from './Pricing';
import Family from './Family';
import PrivacyPolicy from './PrivacyPolicy';
import Login from './Login';
import SignUp from './SignUp';
import { HomePageBlock } from '../containers/Landing';
import {StripeProvider} from 'react-stripe-elements';

const { store, persistor } = getStore();

const AppWrapper = styled.div`
  height: 100%;
  position: relative;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: calc(100% - 47px);
  position: relative;
  top:0;
  & * {
    font-family: 'Open Sans','Helvetica Neue',Arial,Helvetica,sans-serif;
  }
`;

const AboutFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 13px 70px;
  background: #EDF0F1;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  bottom: 0;
  & p {
    margin: 0;
    color: #4E4E4E;
    font-size: 14px;
  }
  & a {
    margin: 0;
    color: #4E4E4E;
    text-decoration: none;
    padding: 15px;
    font-size: 14px;
    cursor: pointer;
  }
  @media(max-width: 992px){
    padding: 15px;
  }
  @media(max-width: 550px){
    padding: 0;
    flex-flow: column;
    text-align: center;
    & p {
      padding: 15px;
      font-size: 12px;
    }
    & a {
      font-size: 12px;
    }
  }
`;
class App extends Component {
  render() {
    return (
      <AppWrapper>
        <ViewWrapper>
          <StripeProvider apiKey="pk_test_bJUkqIPjA1luH4wq0U03Pa7100KW96QOB2">
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Switch>
                      <Route exact path="/" component={HomePageBlock} />
                      <Route exact path="/about" component={About} />
                      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                      <Route exact path="/contact" component={Contact} />
                      <Route exact path="/family" component={Family} />
                      <Route exact path="/pricing" component={Pricing} />
                      <Route exact path="/products" component={Products} />
                      <Route exact path="/complete" component={Complete} />
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/sign-up" component={SignUp} />
                      <ProtectedRoute path="/app" component={ProtectedLayout} />
                    </Switch>
                </BrowserRouter>
              </PersistGate>
            </Provider>
          </StripeProvider>
        </ViewWrapper>
        <AboutFooter>
          <div><a href="/privacy-policy">Privacy Policy</a>|<a href="/contact">Contact</a>|<a href="/about">About Us</a></div>
          <p>® 2019 Remily, Inc.</p>
        </AboutFooter>
      </AppWrapper>
    );
  }
}

export default App;
