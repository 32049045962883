import React, { Component } from 'react';
import styled from 'styled-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import SidebarNav from '../components/SidebarNav';
import Modal from '../components/Modal';
import familyImageIcon from '../assets/img/family-image-icon.png';
import familyImage from '../assets/img/family-image.png';

const FamilyWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  & #ModalCurrentPassword {
    margin-bottom: 40px;
  }
  & #ModalConfirmPassword{
    margin-bottom: 40px;
  }
  @media(max-width: 992px){
    flex-flow: column;
    max-height: calc(100vh - 55px);
  }
  @media(max-width: 550px){
    max-height: calc(100vh - 67px);
  }
`;
const FamilySubWrapper = styled.div`
  width: 100%;
  padding: 48px 0 0 33px;
  background-color: #F0F0F0;
  display: flex;
  flex-flow: column;
  @media(max-width: 992px){
    padding: 15px;
    justify-content: center;
    align-items: center;
  }
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 200;
  color: #E64C3C;
  letter-spacing: -.01em;
  line-height: 41px;
  margin-bottom: 23px;
`;

const PeopleWrapper = styled.div`
  width: 100%;
  padding-right: 85px;
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100% - 51px);
  @media(max-width: 992px){
    justify-content: center;
    align-items: center;
    padding: 25px;
  }
`;

const PersonWrapper = styled.div`
  margin-right: 16px;
  margin-bottom: 20px;
`;

const PersonImage = styled.img`
  height: 134px;
  width: 134px;
  margin-bottom: 9px;
`;

const PersonName = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  line-height: 17px;
  margin-bottom: 3px;
`;

const PersonRelationship = styled.p`
  font-size: 18px;
  margin: 0;
`;


// =========== MODAL ===============
const TransitionOverlay = styled.div`
  position: fixed;
  margin: 0;
  height: calc( 100% - 48px);
  width: 100%;
  z-index: 90;
  pointer-events:none;
  @media(max-width: 992px){
    flex-flow: column;
    max-height: calc(100vh - 75px);
  }
`;
const StyledModal = styled(Modal)`
  height: 100%;
  justify-content: flex-end;
  background: transparent;
  pointer-events:all;
  &.example-appear {
    transform: translateX(100%);
  }
  &.example-appear-active {
    transform: translateX(0px);
    transition: transform 500ms ease-out;
  }
  &.example-enter {
    transform: translateX(100%);
  }
  &.example-enter.example-enter-active {
    transform: translateX(0px);
    transition: transform 500ms ease-out;
  }
  &.example-leave {
    transform: translateX(0px);
  }
  &.example-leave.example-leave-active {
    transform: translateX(100%);
    transition: transform 500ms ease-in;
  }
  @media(max-width: 992px){
    margin-top: 75px;
    height: calc(100%-75px);
    justify-content: flex-end;
    align-items: flex-end;
  }
  @media(max-width: 768px){
    flex-flow: column;
  }
  & #modalInfoWrapper{
    max-width: 580px;
    width: calc(100% - 481px);
    height: 100%;
    max-height: 100%;
    padding-top: 111px;
    align-self: center;
    justify-content: flex-start;
    align-items: center;
    border: none;
    @media(max-width: 992px){
      max-width: calc(100% - 256px);
      width: calc(100% - 256px);
      padding: 15px 0;
      justify-content: center;
    }
    @media(max-width: 768px){
      height: unset;
      max-width: 100%;
      width: 100%;
      padding: 30px 0;
    }
  }
`;
const ModalImage = styled.img`
  width: 241px;
  height: auto;
  margin-bottom: 14px;
`;
const ModalName = styled.p`
  color: #E64C3C;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: -1px;
`;
const ModalRelationship = styled.p`
  font-size: 20px;
  margin: 0 0 5px;
`;
const ModalTimelineLink = styled.p`
  font-size: 18px;
  margin: 0;
`;
const ModalPhotosLink = styled.p`
  font-size: 18px;
  margin: 0;
`;
const ModalDevider = styled.p`
  background-color: #EDF0F1;
  height: 2px;
  width: 241px;
  margin: 25.5px 0;
`;
// =========== END MODAL ===============
class Family extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personModal: false,
    };
    this.handleModalUpdate = this.handleModalUpdate.bind(this);
  }
  handleModalUpdate(modal){
    this.setState(modal)
  };
  peoplePopulator(){
    let peopleArr = Array.apply(null, {length: 24}).map((e, i)=>
      <PersonWrapper key={`personNumber${i}`} onClick={()=>this.handleModalUpdate({personModal: true,})}>
        <PersonImage src={familyImageIcon}/>
        <PersonName>Name</PersonName>
        <PersonRelationship>Relationship</PersonRelationship>
      </PersonWrapper>
    )
    return(
      <PeopleWrapper>
        {peopleArr}
      </PeopleWrapper>
    );
  }
  render() {
    return(
    <FamilyWrapper>
      <SidebarNav />
      <FamilySubWrapper>
        <Title>Family</Title>
          {this.peoplePopulator()}
      </FamilySubWrapper>
      <ReactCSSTransitionGroup
            transitionName='example'
            transitionAppear={true}
            transitionEnter={true}
            transitionLeave={true}
            transitionEnterTimeout={500}
            transitionAppearTimeout={500}
            transitionLeaveTimeout={500}
            component={TransitionOverlay}
      >
      {this.state.personModal && 
        <StyledModal id="FamilyPersonModal" passedHandle={this.handleModalUpdate} passedHandleArg={{personModal: false,}} PassedHeight={"100%"} >
          <ModalImage src={familyImage} alt="Person Image" />
          <ModalName>Name</ModalName>
          <ModalRelationship>Relationship</ModalRelationship>
          <ModalDevider/>
          <ModalTimelineLink>View Timeline</ModalTimelineLink>
          <ModalDevider/>
          <ModalPhotosLink>View Photos</ModalPhotosLink>
          
        </StyledModal>
      }
      </ReactCSSTransitionGroup>
    </FamilyWrapper>
  )}
}

export default Family;