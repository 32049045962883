import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import DropzoneClickComponent from '../components/DropzoneClickComponent';
import AddIcon from '../assets/img/add-icon-2.png';

import { createCollection } from '../actions/apiActions'

import { colors, typography } from '../assets/js/StyleConstants';

const StyledAddCollectionWrapper = styled.div`
  width: 100%;
  padding-bottom: 27.5px;
  padding-top: 15px;
  display: flex;
  @media (max-width: 992px) {
    min-width: 280px;
    height: 61px;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  & #AddCollectionInner{
    border-right: .05em solid black;
  }
`;

const StyledAddCollectionInner = styled.div`
  width: 60%;
  height: 34px;
  padding-right: 10px;
  align-items: center;
  display: flex;
  justify-content: start;
`;
const AddNewIcon = styled.img`
  margin: 0 15px 0 35px;
  font-size: 33px;
  @media (max-width: 992px) {
    margin: auto 15px;
  }
`;

const StyledAddCollectionContent = styled.div`
  width: 100%;
`;

const StyledCollectionNameInput = styled.input`
  border: none;
  color: ${colors.gray2};
  padding-left: 15px;
  width: calc(100% - 15px);
  ${typography.body2}

  &:focus {
    outline-width: 0;
  }
`;
const StyledAddCollectionLabel = styled.p`
  color: #4E4E4E;
  font-size: 16px;
`;

const StyledAddPhotosInner = styled.div`
  width: 100%;
  height: 34px;
  align-items: center;
  display: flex;
  justify-content: start;
  color: ${props => props.collectionSelected ? '#4E4E4E' : 'lightgray'};
  cursor:  ${props => props.collectionSelected ? 'pointer' : 'not-allowed'};
`;

const StyledAddPhotosContent = styled.div`
  width: 100%;
`;
const StyledAddPhotosLabel = styled.p`
  font-size: 16px;
`;

const SearchTitle = styled.h2`
    font-size: 30px;
    font-weight: 200;
    color: #E64C3C;
`;
class AddCollectionRow extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      editMode: false,
      name: "",
    };
    this.handleSubmitCollection = this.handleSubmitCollection.bind(this);
    this.handleAddPhotosClick = this.handleAddPhotosClick.bind(this);
  }
  

  handleChange = (e) => {
    this.setState({ name: e.target.value });
  };

  setEditMode = mode => () => {
    this.setState({ editMode: mode });
  };

  handleSubmitCollection(e){
    e.preventDefault();
    e.stopPropagation();
    this.props.createCollection(this.state.name, this.props.jwt);
    this.setState({...this.state, name: "" });
  };
  handleAddPhotosClick(){
    if(this.props.collecion){

    }
    else{
      alert("Please create, or select, a collection to add your photos to.")
    }
  }
  render() {
    return (
      <StyledAddCollectionWrapper onClick={this.setEditMode(true)}>
        { !this.props.searchBool && <StyledAddCollectionInner id="AddCollectionInner">
        <AddNewIcon src={AddIcon} alt="add a collection" />
          <StyledAddCollectionContent>
            {this.state.editMode ? (
              <form onSubmit={this.handleSubmitCollection}>
                <StyledCollectionNameInput 
                  onChange={this.handleChange}
                  name="name"
                  placeholder="Collection Name"
                  autoFocus
                  value={this.state.name} />
              </form>
            ) : (
              <StyledAddCollectionLabel>Create Collection</StyledAddCollectionLabel>
            )}
          </StyledAddCollectionContent>
        </StyledAddCollectionInner>}
        
        {
            this.props.searchBool && 
            <SearchTitle style={{"width": "100%", "textAlign": "center"}}>Collections Results</SearchTitle>
        }
        { !this.props.searchBool &&
          <div>

            {this.props.collection ? <StyledAddPhotosInner collectionSelected={(this.props.collection)} onClick={this.handleAddPhotosClick}>
              <DropzoneClickComponent imageUploadOverlayHandler={this.props.imageUploadOverlayHandler} dzwidth="100%" dzmargin="0">
                <AddNewIcon src={AddIcon} alt="add photos" />
                <StyledAddPhotosContent>
                  <StyledAddPhotosLabel >
                    Add Photos
                  </StyledAddPhotosLabel>
                </StyledAddPhotosContent>
              </DropzoneClickComponent>
            </StyledAddPhotosInner>
            :
            <StyledAddPhotosInner collectionSelected={(this.props.collection)} onClick={this.handleAddPhotosClick}>
              <AddNewIcon src={AddIcon} alt="add photos" />
              <StyledAddPhotosContent>
                <StyledAddPhotosLabel >
                  Add Photos
                </StyledAddPhotosLabel>
              </StyledAddPhotosContent>
            </StyledAddPhotosInner>
            }
          </div>}
      </StyledAddCollectionWrapper>
    );
  }
}
const mapStateToProps = state => {
  let returnedProps = {};
  if(state.apiReducer.jwt){
    returnedProps.jwt = state.apiReducer.jwt;
  };
  if(state.apiReducer.searchBool){
    returnedProps.searchBool = state.apiReducer.searchBool;
  }
  if(state.apiReducer.collection){
    returnedProps.collection = state.apiReducer.collection.data;
  };
  return {...returnedProps};
}
export default connect(mapStateToProps, {createCollection}) (AddCollectionRow);