import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { componantDispatcher } from '../actions/apiActions'

import { updateObj, destroyImage, destroyCollection, addJournalEntry, updateCollectionImage } from '../actions/apiActions'
import theLogo from '../assets/img/remily-logo-simbol.png';
import Modal from './Modal';
import fileIconSrc from '../assets/img/file.svg';
import Button from './Button2';
import Dropdown from './DropdownUniversalStyled';

const OverlayBackground = styled.div`
    background: transparent; 
    position: fixed;
    top: 0;
    bottom: 47px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    overflow-y: scroll;
    @media (max-width: 992px) {
      flex-flow: column;
    }
    &.example-appear {
      transform: translateX(100%);
    }
    &.example-appear-active {
      transform: translateX(0px);
      transition: transform 500ms ease-out;
    }
    &.example-enter {
      transform: translateX(100%);
    }
    &.example-enter.example-enter-active {
      transform: translateX(0px);
      transition: transform 500ms ease-out;
    }
    &.example-leave {
      transform: translateX(0px);
    }
    &.example-leave.example-leave-active {
      transform: translateX(100%);
      transition: transform 500ms ease-in;
    }
`;
const ObjEditorWrapper = styled.div`
    /* width: calc(61% - 200px); */
    width: calc(61% - 73px);
    height: 100%;
    padding: 46px;
    background: #FFFFFF; 
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
    @media (max-width: 1230px) {
      width: calc(45%);
    }
    @media (max-width: 992px) {
      width: 100%;
      height: calc(100% - 265px);
      margin-bottom: 45px;
    }
    @media (max-width: 992px) and (max-height: 799px) {
      height: calc(100% - 71px);
    }
    @media (max-width: 550px) {      
      margin-bottom: 65px;
    }
    & #dropdownForCollectionInput{
      max-height: 80%;
    }
    /* & #dropdownForCollectionInput{
      overflow-y: scroll;
    } */
`;
const ObjEditorTitle = styled.div`
  color: #E64C3C;
  font-size: 28px;
  font-weight: lighter;
`;
const StyledDropdown = styled(Dropdown)`
      margin: 0 0 0 auto;
      width: 100%;
      max-width: 100%;
    & button{
      width: 100%;
      margin: 0;
    }
    & #dropdownMenu{
      position: relative;
      top:0;
      width: 100%;
      margin: 0;
    }
`;
const ImageWrapper = styled.div`
    width: 100%;
    height: calc(100% - 375px);
    min-height: 50px;
    margin: 35px auto 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
`;
const Image = styled.img`
    align-self: center;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
`;
const ContentWrapper = styled.div`
    height: unset;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: space-evenly;
    align-self: center;
    @media (max-width: 992px) {
      width: 100%;
      min-width: 300px;
    }
`;
const Content = styled.div`
    max-width: 100%;
    margin-bottom: 15px;
    display: flex;
    @media (max-width: 992px) {
      margin-bottom: 15px;  
    }
`;
const ShortInputsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  & #EditLocationInput{
    width: calc(50% - 10px);
    height: 40px;
    margin: 0;
  }
  & #EditDateInput{
    width: calc(50% - 10px);
    height: 40px;
    margin: 0;
  }
`;
const ContentInput = styled.input`
    width: 100%;
    height: 40px;
    padding: 5px;
    margin: 0 0 0 auto;
    border: 1px solid #CDCDCD;
    background: #D0D1D1;
    &::placeholder{
        color: #9D9D9D;
        font-size: 14px;
    }
`;
const ContentTextArea = styled.textarea`
    width: 100%;
    height: 90px;
    max-height: 70vh;
    
    padding: 5px;
    margin: 0 0 0 auto;
    border: 1px solid #CDCDCD;
    background: #D0D1D1;
    resize: none;
    &::placeholder{
        color: #9D9D9D;
        font-size: 14px;
    }
    @media (max-width: 992px) {
      max-height: 30vh;
    }
`;
const JournalContentTextArea = styled.textarea`
    width: 100%;
    padding: 5px;
    margin: 0 0 0 auto;
    border: 1px solid #CDCDCD;
    background: #D0D1D1;
    resize: none;
    &::placeholder{
        color: #9D9D9D;
        font-size: 14px;
    }
`;
const ButtonWrapper = styled.div`
    background: #FFFFFF; 
    display: flex;
    justify-content: space-between;
`;
const StyledButton = styled(Button)`
  width: 191px;
  max-width: calc(33% - 10px);
  height: 42px;
  border-radius: 0;
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  margin: 0;
  background-color: #E64C3C;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
`
// =========== MODAL ===============
const StyledModal = styled(Modal)`
  & #modalInfoWrapper{
    padding: 62px 28px;
  }
  & #passwordResetSuccessLogo{
    margin-top: 40px;
  }
  & #usernameResetSuccessLogo{
    margin-top: 40px;
  }
  & #activatingYourAccountNowTitle{
    text-align: center;
    align-self: center;
  }
  & #activatingYourAccountNowButton{
    margin: auto;
  }
  
`;
const CollectionPhotoSetter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    & button {
      background-color: white;
      color: black;
      outline: none !important;
      max-width: 100%;
    }
`;
const ModalButtonWrapper = styled.div`
    width: 410px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
`;
const ModalButton = styled(Button)`
    width: 191px;
    max-width: 45%;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 6px;
    background: #E64C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
`
const CollectionTitle = styled.h1`
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    width: 100%;
`;
const ModalContentTitle = styled.h3`
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin: 0 0 10px;
`;
const ModalImage = styled.img`
    margin: 0 0 25px;
`;;
// =========== END MODAL ===============
class ObjEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      newApiKey: "",
      deleteFlag: false,
    };
    this.handleDropdown = this.handleDropdown.bind(this);
  }
    editImageToShow = () =>{
        if(this.currentEditObj().thumnail_image) return this.currentEditObj().thumnail_image
        if(this.currentEditObj().url) return this.currentEditObj().url
        return fileIconSrc
    }
    currentEditObj = () => {
        let apiObj = this.props.apiEditObj
        if(apiObj && apiObj.apikey === this.props.passedObj.apikey && !("url" in this.props.passedObj)){ 
            this.props.forceEditObjectToBeImage(apiObj);
        }
        return this.props.passedObj;
    }
    childHandle = (e) => {
        e.stopPropagation();
    }

    handleDropdown = (newApiKey) => {
      // console.log("this.props.collections", this.props.collections);
      
      this.setState({newApiKey});
    }
    gpsStringToNum(str, places){
      let strArr = str.split("/")
      let gps = (strArr[0]*1 / strArr[1]*1).toFixed(places)
      return gps
    }
    setLocation(editObj){
      let meta = editObj.meta_data
      if(meta && meta.gps_latitude && meta.gps_latitude[0]){
        let lat1 = this.gpsStringToNum(meta.gps_latitude[0].toString(), 0),
            lat2 = this.gpsStringToNum(meta.gps_latitude[1].toString(), 0),
            lat3 = this.gpsStringToNum(meta.gps_latitude[2].toString(), 2),
            long1 = this.gpsStringToNum(meta.gps_longitude[0].toString(), 0),
            long2 = this.gpsStringToNum(meta.gps_longitude[1].toString(), 0),
            long3 = this.gpsStringToNum(meta.gps_longitude[2].toString(), 2),
            firstCord = meta.gps_latitude_ref,
            secondCord = meta.gps_longitude_ref,
            location = `${lat1} deg ${lat2}' ${firstCord} ${lat3}, ${long1} deg ${long2}' ${secondCord} ${long3},`
        return location
      }
      return "Unknown"
    }
    setDate(editObj){
      let meta = editObj.meta_data
      if(meta && meta.date_time){
        let neededDate = meta.date_time.toString()
        let newString = neededDate.slice(0,4)+"-"+neededDate.slice(5,7)+"-"+neededDate.slice(8,10);
        return newString
      }
      if(meta && meta.gps_date_stamp){
        let neededDate = meta.gps_date_stamp.toString()
        let newString = neededDate.slice(0,4)+"-"+neededDate.slice(5,7)+"-"+neededDate.slice(8,10);
        return newString
      }
      return "Unknown"
    }
    getItemCollection(nonSearchCollections){
      console.log("nonSearchCollections", nonSearchCollections);
      
        let itemsCollection = nonSearchCollections.data.collections.filter(collection=>{
        let theObj = this.currentEditObj()
        if(collection && theObj.collections && collection.apikey === theObj.collections[0]){
          return true
        }
        return false
      });
      console.log("itemsCollection", itemsCollection)
      if(itemsCollection[0] && itemsCollection[0].name){
        return itemsCollection[0]
      }
      if(itemsCollection.length===0){
        return this.props.collection
      }
      return {name: ""}
    }
    componentWillUnmount(){
      this.props.editObjSetter({}, "none")
      this.props.componantDispatcher({type: "SET_IMAGE", payload: {}})
    }
  render() { 
    return (
        <OverlayBackground onClick={()=>this.props.editObjSetter({})} className={this.props.className}>
          <ObjEditorWrapper onClick={(e)=>this.childHandle(e)} className={this.props.className}>
            <ObjEditorTitle>Edit {this.props.editObjType === "journal" ? "Journal" : this.props.editObjType === "collection" ? "Collection" : "Photo"} Info</ObjEditorTitle>
            {this.props.editObjType === "journal" && <br />}
            {(this.currentEditObj()) && this.props.editObjType !== "journal"  &&<ImageWrapper><Image src={this.editImageToShow()}/></ImageWrapper>}
            
            {(this.currentEditObj()) && <ContentWrapper heightSetter={this.props.editObjType === "journal" ? "90%" : "30%"}>
              { this.props.editObjType === "collection" &&
                  <CollectionTitle>Collection Title</CollectionTitle>
              }
              <Content>
                <ContentInput id="nameInput" onChange={this.props.handleEditObjChange} value={`${this.currentEditObj().name}`} placeholder="Name" />
              </Content>
              {this.props.editObjType === "image"  && <Content>
                <ContentTextArea id="descInput" onChange={this.props.handleEditObjChange} value={`${this.currentEditObj().summary}`} rows="6" placeholder="Tell us about this photo"  />
              </Content>}
              {this.props.editObjType === "journal" && <Content>
                <JournalContentTextArea id="summary" rows="23" onChange={this.props.handleEditObjChange} value={`${this.currentEditObj().summary}`} placeholder="Tell us about your day"  />
              </Content>}
              { this.props.editObjType !== "collection" && this.props.editObjType !== "journal" &&
                <ShortInputsWrapper>
                    <ContentInput id="EditLocationInput" onChange={this.props.handleEditObjChange} value={`${this.setLocation(this.currentEditObj())}`} placeholder="Location"  />
                    <ContentInput id="EditDateInput" onChange={this.props.handleEditObjChange} value={`${this.setDate(this.currentEditObj())}`} placeholder="Date"  />
                </ShortInputsWrapper>
              }
              { this.props.editObjType !== "collection" && 
                  <Content onClick={()=>{ let element = document.getElementById("dropdownForCollectionInput"); element.style.overflowY === "scroll"? element.style.overflowY = "hidden":element.style.overflowY = "scroll"}} id="dropdownForCollectionInput">
                      <StyledDropdown id="collectionInput" items={this.props.nonSearchCollections || {collections: []}} selectedItem={this.getItemCollection(this.props.nonSearchCollections)} onChange={this.handleDropdown} />
                  </Content>
              }


              <ButtonWrapper>
                {this.props.editObjType !== "journal"  &&
                  <StyledButton onClick={()=>{this.props.updateObj(this.props.jwt, this.currentEditObj(), this.props.editObjType, this.state.newApiKey || this.props.collection.apikey ); this.props.editObjSetter({})}} text="Save" />
                }
                {this.props.editObjType === "journal" && 
                  <StyledButton onClick={
                    ()=>{
                      if(this.currentEditObj().apikey === "1"){this.props.addJournalEntry(this.props.jwt, this.currentEditObj(), this.props.collection.apikey)};
                      if(this.currentEditObj().apikey !== "1"){
                        this.props.updateObj(this.props.jwt, this.currentEditObj(), "image", this.state.newApiKey || this.props.collection.apikey);
                      }
                      this.props.editObjSetter({})
                    }
                  }
                  text="Save" />
                }
                {(this.props.editObjType === "image" || "journal") && 
                  <StyledButton onClick={()=>{this.setState({deleteFlag: !this.state.deleteFlag})}}text="Delete" />}
                <StyledButton onClick={()=>this.props.editObjSetter({}) } text="Cancel" />
              </ButtonWrapper>
            </ContentWrapper>}
            {this.state.deleteFlag && 
            <StyledModal id="deleteModal">
              <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" />
              <ModalContentTitle>Are you sure you want to delete this?</ModalContentTitle>
              <ModalButtonWrapper>
                <ModalButton onClick={()=>{this.setState({deleteFlag: !this.state.deleteFlag})}} text="Cancel" />
                {(this.props.editObjType === "image" || this.props.editObjType === "journal") &&
                  <ModalButton onClick={()=>{this.props.destroyImage(this.props.jwt, this.currentEditObj().apikey, this.props.collection.apikey); this.setState({deleteFlag: !this.state.deleteFlag}); this.props.editObjSetter({})}} text="Delete" />
                }
                {this.props.editObjType === "collection" &&
                  <ModalButton onClick={()=>{ this.props.destroyCollection(this.props.collection.apikey, this.props.jwt); this.setState({deleteFlag: !this.state.deleteFlag}); this.props.editObjSetter({})}} text="Delete" />
                }
              </ModalButtonWrapper>
              
            </StyledModal>
          }
          {this.props.editObjType === "image" && 
            <CollectionPhotoSetter>
              <StyledButton onClick={()=>{
                      this.props.updateCollectionImage(this.props.jwt, this.props.collection.apikey, this.currentEditObj().apikey )
                    }} text="Set as collection image" />
            </CollectionPhotoSetter>
          }
          </ObjEditorWrapper>
        </OverlayBackground>
    );
  }
}
 
const mapStateToProps = state => {
    let returnedProps = {};
    if(state.apiReducer.searchBool){
        returnedProps.searchBool = state.apiReducer.searchBool;
        if(state.apiReducer && state.apiReducer.searchCollections && state.apiReducer.searchCollections.data.collections){
            returnedProps.collections = state.apiReducer.searchCollections;
        };
        if(state.apiReducer && state.apiReducer.searchCollection){
            returnedProps.collection = state.apiReducer.searchCollection;
        }; 
    }
    
    if(state.apiReducer.collection){
      returnedProps.nonSearchCollection = state.apiReducer.collection.data;
    };
    
    if(state.apiReducer && state.apiReducer.collections && state.apiReducer.collections.data.collections){
        returnedProps.nonSearchCollections = state.apiReducer.collections;
    };
    if(!state.apiReducer.searchBool){
        if(state.apiReducer && state.apiReducer.collections && state.apiReducer.collections.data.collections){
            returnedProps.collections = state.apiReducer.collections;
        };
        if(state.apiReducer.collection){
            returnedProps.collection = state.apiReducer.collection.data;
        };
    }
    if(state.apiReducer.jwt){
      returnedProps.jwt = state.apiReducer.jwt;
    }; 
    if(state.apiReducer.apiEditObj){
      returnedProps.apiEditObj = state.apiReducer.apiEditObj;
    };  
    return {...returnedProps};
}
 
export default connect(mapStateToProps, {updateCollectionImage, addJournalEntry, componantDispatcher, destroyCollection, destroyImage, updateObj}) (ObjEditor);