import React from "react";
import styled from 'styled-components';
import Slider from "react-slick";
import adultBeautifulBrunette from '../assets/img/adult-beautiful-brunette-1549280.jpg';
import affectionBrideAndGroom from '../assets/img/affection-bride-bride-and-groom-2253842.jpg';
import childCuteDad from '../assets/img/child-cute-dad-105952.jpg';
import Button from '../components/Button';
import { colors, typography } from '../assets/js/StyleConstants';
import Container from '../components/layout/Container';



const SliderWrapper = styled.div`
  width: 100%;
  padding: 0;
  position: relative;
`;
const StyledMastheadContainer = styled(Container)`
  max-width: 100%;
  margin: 185px 0 0 0;
  padding: 0;
`;
const Masthead1 = styled.div`
  background: linear-gradient(
    rgba(44, 62, 80, 0.5),
    rgba(44, 62, 80, 0.5)
  ), url(${adultBeautifulBrunette});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 418.9px;
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 44px 57px;
`;
const Masthead3 = styled.div`
  background: linear-gradient(
    rgba(44, 62, 80, 0.5),
    rgba(44, 62, 80, 0.5)
  ), url(${affectionBrideAndGroom});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 418.9px;
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 44px 57px;
`;
const Masthead2 = styled.div`
  background: linear-gradient(
    rgba(44, 62, 80, 0.5),
    rgba(44, 62, 80, 0.5)
  ), url(${childCuteDad});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 418.9px;
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 44px 57px;
`;

const MeastheadTitle = styled.h1`
  color: ${colors.white};
  font-size: 40px;
  line-height: 36px;
  font-weight: 900;
  letter-spacing: -1px;
`;

const MastHeadContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 39px 5%;
  max-width: 453px;
  width: 100%;
  z-index: 2;
`;

const MastheadButton = styled(Button)`
  width: 46%;
  margin-right: 4%;
  max-height: 191px;
  border-radius: 0;
  font-weight: 400;
  font-size: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

const SubCopy = styled.p`
  color: ${colors.white};
  ${typography.body2};
  line-height: 22px;
  font-weight: 400;
`;


class LandingHeroSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000
    };
    return (
      <SliderWrapper>
        <Slider {...settings}>

          <Masthead1>
              <StyledMastheadContainer/>
          </Masthead1>
          <Masthead2>
              <StyledMastheadContainer/>
          </Masthead2>
          <Masthead3>
              <StyledMastheadContainer/>
          </Masthead3>
        </Slider>
        <MastHeadContent>
          <MeastheadTitle>Store, Share, Cherish</MeastheadTitle>
          <SubCopy>
              {`Create a timeline of your most cherished memories. You can then share them with just your family and friends—there's no need to have them open to the public unless you want to. These are your memories to share and cherish as you wish. `}
          </SubCopy>
          <MastheadButton href="/login">Sign-In</MastheadButton>
          <MastheadButton href="/sign-up">Register</MastheadButton>
        </MastHeadContent>
      </SliderWrapper>
    );
  }
}
export default LandingHeroSlider;
