import React, {Component} from 'react';
import {RadioGroup, RadioButton} from 'react-custom-radio';
import { componantDispatcher } from '../actions/apiActions'
import styled from 'styled-components';
import { connect } from 'react-redux';


const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    align-items: center;
    justify-content: center;
    & *.disabled{
        cursor: not-allowed;
        color: lightgray;
        & *{
            border-color: lightgray;
        }
    }
`;
const StyledRadioButton = styled(RadioButton)`
    border-radius: 100%;
    width: 13px;
    height: 13px;
    padding: 0;
    background: ${props => props.disabled ? "radial-gradient(#3B97D3 50%, white 50%);" : "white"};
    border: 1px solid #707070;
    margin-right: 10px;
    outline: none;
    &:hover{
        cursor: pointer;
    }
`;
const StyledLabel = styled.label`
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
class RadioStyled extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPaymentOption: '',
        };
    }

    get name() {
        return 'customExample';
    }

    handlePayChange = (selectedPaymentOption) => {
        this.setState({selectedPaymentOption});
        this.props.componantDispatcher({type: "SET_PAYMENT_METHOD", payload: selectedPaymentOption});
    };

    render() {
        return (
            <StyledRadioGroup
                name={this.name}
                selectedValue={this.state.selectedPaymentOption}
                onChange={this.handlePayChange}
                id="paymentMethodSelector"
            >
                <StyledLabel>
                    <StyledRadioButton disabled={this.state.selectedPaymentOption === "Credit Card"?true:true} value="Credit Card" className="radio-button"/>
                    Credit Card
                </StyledLabel>
                <StyledLabel className="disabled">
                    <StyledRadioButton disabled={this.state.selectedPaymentOption === "PayPal"?false:false} value="PayPal" className="radio-button"/>
                    PayPal - Comming soon.
                </StyledLabel>
            </StyledRadioGroup>
        )
    }
};

function mapStateToProps(state) {
    //console.log(state);
    
    let returnedProps = {};
    // if(state.user){
    //   returnedProps.user = state.user;
    // };
    return {...returnedProps};
  }
export default connect(mapStateToProps, {componantDispatcher})(RadioStyled);