import React, { Component } from 'react';
import { connect } from 'react-redux';
import { componantDispatcher } from '../actions/apiActions'
import styled from 'styled-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Elements} from 'react-stripe-elements';

import Navbar from '../components/Navbar';
import Modal from '../components/Modal';
import Button from '../components/Button2';
import InjectedCheckoutForm from '../components/CheckoutForm';
import theLogo from '../assets/img/remily-logo-blue.png';

const ProductsWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const ProductsContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 25px;
`;

const TopLogo = styled.img`
    margin: 45px auto auto auto;
    width: 80px;
`;
// =========== ERROR MODAL ===============

const TransitionOverlay = styled.div`
    position: absolute;
    top: 69px;
    margin: 0;
    height: calc(100% - 69px);
    width: 100%;
    z-index: 90;
    pointer-events:none;
`;
const StyledModal = styled(Modal)`
    height: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    background: transparent;
    pointer-events:all;
    &.example-appear {
        transform: translateX(100%);
    }
    &.example-appear-active {
        transform: translateX(0px);
        transition: transform 500ms ease-out;
    }
    &.example-enter {
        transform: translateX(100%);
    }
    &.example-enter.example-enter-active {
        transform: translateX(0px);
        transition: transform 500ms ease-out;
    }
    &.example-leave {
        transform: translateX(0px);
    }
    &.example-leave.example-leave-active {
        transform: translateX(100%);
        transition: transform 500ms ease-in;
    }
    & #modalInfoWrapper{
        padding: 25px;
        align-self: flex-start;
        min-width: 230px;
    }
    & #usernameResetSuccessLogo{
        margin-top: 40px;
    }
`;
const ModalButton = styled(Button)`
    width: 191px;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 6px;
    background: #E64C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
`;
const ModalImage = styled.img`
    margin: 15px;
`;
const ModalP = styled.p`
    font-size: 16px;
    max-width: 300px;
    text-align: center;
    line-height: 21px;
`;
// =========== END ERROR MODAL ===============



class Products extends Component {
  constructor(props) {
      super(props);
      this.state = {
          email: '',
          password: '',
          paymentMethod: '',
          countTracker: 0,
          welcomed: false,
          paymentStatementFlag: false,
          modalFlag: "",
          noPaymentError: false,
      };
      this.handleChange = this.handleChange.bind(this);
      this.handlePaymentChange = this.handlePaymentChange.bind(this);
  }
  handleChange = (event, key) => {
      const { value } = event.target;
      this.setState({
          [key]: value,
      });
  };
  handlePaymentChange(event) {
      this.setState({
          paymentMethod: event.target.value
      });
  }
  componentDidMount(){
    if(this.props.paymentMethod === "success"){
      console.log("Starting with success");
      this.props.componantDispatcher({type: "SET_PAYMENT_METHOD", payload: "fail"})
    }
    if(this.props.loginError && this.props.loginError.error && this.props.loginError.error === "no_payment_method" && !this.state.paymentStatementFlag){
      this.setState({
        modalFlag: "no_payment_method",
        paymentStatementFlag: true,
        noPaymentError: true,
      })
    }
  }
  componentDidUpdate(prevProps){
      console.log("hit");
      if(this.props.newUserNoPay && !this.state.welcomed){
      this.setState({
          modalFlag: "AccountActivated",
          welcomed: true,
      })
    }
    if(this.props.paymentMethod === "success"){
      console.log("hit");
      if(this.state.noPaymentError){
        this.props.componantDispatcher({type: "RECEIVE_USER", payload: {}})
        this.props.history.push('/app/photos')
      }
      else{
        this.props.history.push('/complete')
      }
    }
  }
  render() {
    return (
      <ProductsWrapper>
        <Navbar/>
        
        <ProductsContentWrapper>
          <TopLogo src={theLogo} alt="Remily Logo"/>
        </ProductsContentWrapper>
        <Elements>
          <InjectedCheckoutForm {...this.props}/>
        </Elements>
        {/* ===========NOTIFICATION MODALS=========== */}
        
        <ReactCSSTransitionGroup
          transitionName='example'
          transitionAppear={true}
          transitionEnter={true}
          transitionLeave={true}
          transitionEnterTimeout={500}
          transitionAppearTimeout={500}
          transitionLeaveTimeout={500}
          component={TransitionOverlay}
        >
          {this.state.modalFlag==="AccountActivated" && 
            <StyledModal id="signUpEmailInUseModalWrapper" passedHandle={()=>{
              this.setState({modalFlag: ""}); 
              this.props.componantDispatcher({type: "LOGIN_ERROR", payload:{}});
              }}
            >
            <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" id="passwordResetSuccessLogo" />
            <ModalP>Your account has been successfully created.<br/> Please check for an email from us for instructions on how to activate your account.</ModalP>
            <ModalButton 
            onClick={()=>{
              this.setState({modalFlag: ""}); 
              this.props.componantDispatcher({type: "LOGIN_ERROR", payload:{}});
            }} 
            text="Clear" 
            />
            </StyledModal>
          }
          {this.state.modalFlag==="no_payment_method" && 
            <StyledModal id="noPaymentModalWrapper" passedHandle={()=>{
              this.setState({modalFlag: ""}); 
              this.props.componantDispatcher({type: "LOGIN_ERROR", payload:{}});
              }}
            >
            <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" id="passwordResetSuccessLogo" />
            <ModalP>Your account has been successfully created. However, our records show that you have not picked a plan. Please do so below.</ModalP>
            <ModalButton 
            onClick={()=>{
              this.setState({modalFlag: ""}); 
              this.props.componantDispatcher({type: "LOGIN_ERROR", payload:{}});
            }} 
            text="Clear" 
            />
            </StyledModal>
          }
        </ReactCSSTransitionGroup>
        {/* ===========NOTIFICATION MODALS END=========== */}
      </ProductsWrapper>
    );
  }
}
const mapStateToProps = state => {
  console.log(state);
  let returnedProps = {};

  if(state.apiReducer && state.apiReducer.jwt){
      returnedProps.jwt = state.apiReducer.jwt;
  };
  if(state.apiReducer && state.apiReducer.newUserNoPay){
      returnedProps.newUserNoPay = state.apiReducer.newUserNoPay;
  };
  if(state.apiReducer && state.apiReducer.loginError){
      returnedProps.loginError = state.apiReducer.loginError;
  };
  if(state.apiReducer && state.apiReducer.paymentMethod){
      returnedProps.paymentMethod = state.apiReducer.paymentMethod;
  }; 
  if(state.apiReducer.selectedPlanId){
      returnedProps.selectedPlanId = state.apiReducer.selectedPlanId;
  };
  return {...returnedProps};
}

export default connect(mapStateToProps, {componantDispatcher}) (Products);
