import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'

import SideNavLogo from '../assets/img/side-nav-logo.png';
import HamLogo from '../assets/img/remily-logo.svg';
import { typography } from '../assets/js/StyleConstants';
import hamburgerImg from '../assets/img/hamburger.svg';

const SidebarNavContainer = styled.div`
  background: #2C3E50;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  min-width: 200px;
  height: 100%;
  overflow-y: scroll;
  @media (max-width: 992px) {
    position: relative;
    width: 100%;
    height: 75px;
    min-height: 75px;
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  width: 97px;
  height: 115px;
  margin: 50px 0 0px;
  
  @media (max-width: 992px) {
    display: none;
  }
`;
const MobileLogo = styled.img`
  width: 97px;
  height: 115px;
  margin: 50px 0 11px;
  display: none;
  
  @media (max-width: 992px) {
    display: inline-block;
    margin: 15px 0 15px 20px;
    width: unset;
    height: 45px;
  }
`;

const NavMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  transition: .3s height ease-in-out;
  display: block;
  overflow: hidden;

  @media (max-width: 992px) {
    height: ${props => (props.showMobileNav ? '100%' : '0%')};
  }
`;

const NavItem = styled.li`
  text-align: center;
  ${typography.body2};
  transition: .3s all ease-in-out;
  overflow: hidden;

  @media (max-width: 992px) {
    height: ${props => (props.showMobileNav ? null : '0px')};
    border-bottom: ${props => (props.showMobileNav ? `1px solid #FFFFFF;` : 'none')};
  }
  &:hover div a{
    color: #E64C3C !important;
    font-weight: 300;
  }
`;

const NavLink = styled.div`
  display: block;
  padding: 10px 0;
  & a{
    color: ${props => (props.NavString === window.location.pathname ? "#E64C3C" : "#FFFFFF")} !important;
    font-size: 16px;
    font-weight: ${props => (props.NavString === window.location.pathname ? "300" : "400")};
  }
`;

const Hamburger = styled.div`
  padding: 15px 0;
  display: none;
  position: fixed;
  top: 10px;
  right: 25px;
  @media (max-width: 992px) {
    display: block;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 30px;
    height: 25px;
  }
`;

const menuItems = [{
  label: 'Home',
  path: '/',
}, {
  label: 'Photos',
  path: '/app/photos',
}, {
  label: 'Family',
  path: '/app/family',
}, {
  label: 'Account',
  path: '/app/account',
}, {
  label: 'Log Out',
  path: '/login',
}];

class SidebarNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileNav: false,
      animate: false,
      currentNav: '',
    }
  }

  handleMobileClick = () => {
    this.setState({
      showMobileNav: !this.state.showMobileNav
    });
    let targetElement = document.getElementById("SidebarNavContainer")
    let secondaryElement = document.getElementById("ColectionSidebarID")
    //console.log(targetElement);
    console.log("secondaryElement", secondaryElement);
    
    if(!this.state.showMobileNav){
      targetElement.style.setProperty('flex', 'none' , 'important');
      targetElement.style.setProperty('height', '537px' , 'important');
      targetElement.style.setProperty('width', '100%' , 'important');
      targetElement.style.setProperty('position', 'absolute' , 'important');
      targetElement.style.setProperty('z-index', '100' , 'important');
    }
    if(this.state.showMobileNav){
      targetElement.removeAttribute("style")
    }
  }
  render() {
    return (
      <SidebarNavContainer id="SidebarNavContainer">
        <a href="/photos">
          <Logo src={SideNavLogo} alt="Remily logo" />
          <MobileLogo src={HamLogo} alt="Remily logo" />
        </a>
        <Hamburger onClick={this.handleMobileClick}>
          <img src={hamburgerImg} alt="Menu Icon" />
        </Hamburger>
        <NavMenu showMobileNav={this.state.showMobileNav}>

          {menuItems.map((item, index) => (
            <NavItem key={index} showMobileNav={this.state.showMobileNav} animate={this.state.animate}>
              <NavLink NavString={item.path}>
                <Link to={item.path}>{item.label}</Link>
              </NavLink>
            </NavItem>
          ))}
        </NavMenu>
      </SidebarNavContainer >
    )

  }
}

export default SidebarNav;
