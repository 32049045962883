import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import SidebarNav from '../components/SidebarNav';
import ProfPic from '../assets/img/prof-pic.png';
import UserPhoto from '../assets/img/icon-1.svg';
import ImageIcon from '../assets/img/icon-2.svg';
import UploadPhotos from '../assets/img/icon-3.svg';
import Modal from '../components/Modal';
import Button from '../components/Button2';
import theLogo from '../assets/img/remily-logo-simbol.png';
import DropdownStyled from '../components/DropdownStyled';

const AccountWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  & #ModalCurrentPassword {
    margin-bottom: 40px;
  }
  & #ModalConfirmPassword{
    margin-bottom: 40px;
  }
  @media(max-width: 992px){
    flex-flow: column;
  }
  @media(max-width: 768px){
    flex: unset;
    height: auto;
  }
`;
const AccountSubWrapper = styled.div`
  padding: 5%;
  display: flex;
  flex-flow: column;
  max-height: auto;
  width: 100%;
  @media(max-width: 1230px){
    padding: 15px;
  }
  @media(max-width: 768px){
    justify-content: center;
    align-items: center;
  }
`;
const Title = styled.h1`
  font-size: 30px;
  font-weight: 200;
  color: #E64C3C;
`;
const UserInfoWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  @media(max-width: 768px){
    flex-flow: column;
    flex: unset
  }
`;
const UserInfo = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 9%;
  max-width: 100%;
  & h2 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    color: #3B97D3;
    margin: 13px 0 0;
    @media(max-width: 500px){  
      margin: 0 0 0px;
      font-size: 18px;
      line-height: 18px;
    }
  }
  & p {
    font-size: 12px;
    line-height: 30px;
    font-weight: 400;
    color: #4E4E4E;
    margin: 0 0 33px;
    word-wrap: break-word;
    max-width: 95%;  
    @media(max-width: 500px){
      margin: 0 0 10px;
      font-size: 18px;
      line-height: 18px;
    }
  }
  @media(max-width: 1200px){  
    margin-right: 0px;
  }
  @media(max-width: 768px){  
    justify-content: center;
  }
`;
const UserImage = styled.img`
  align-self: center;
  height: auto;
  width: 100%;
  max-width: 100%;
  @media(max-width: 768px){  
    margin-bottom: 25px;
  }
`;
const InnerUserInfo = styled.div`
  @media(max-width: 768px){  
    height: 230px;
    margin-left: 10px;
  }
`;
const ModalLink = styled.div`
  font-size: 16px;
  border-top: solid 2px #EDF0F1;
  padding: 20px 0;
  &:hover{
    cursor: pointer;
    color: #3B97D3;
  }
  @media(max-width: 768px){  
    padding: 10px 0 ;
  }
`;
const PlanInfoWrapper = styled.div`
  width: 100%;
  margin-top: -2.5%;
  margin-right: -2.5%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  @media(max-width: 1230px){
    padding: 1.5% 0% 1.5% 2%;
  }
  @media(max-width: 768px){
    padding-top: 25px;
    justify-content: flex-start;
    align-content: space-evenly;
  }
`;
const PlanBox = styled.div`
  min-width: 42%;
  min-height: 31%;
  max-width: 269px;
  max-height: 247px;
  margin: 4%;
  padding: 5%;
  border: solid .5px #707070;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  @media(max-width: 1230px){
    margin: 2%;
  }
  @media(max-width: 768px){
    padding: 0%;
    border: none;
  }
`;
const BoxImage = styled.img`
  height: 74px;
  align-self: center;
  @media(max-width: 992px){
    height: 50px;
  }
`;
const BoxTitle = styled.p`
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  margin: 20px 0;
  @media(max-width: 992px){
    margin: 10px 0;
    font-size: 16px;
  }
`;
const BoxData = styled.p`
  font-size: 40px;
  line-height: 40px;
  height: 40px;
  font-weight: 600;
  color: #3B97D3;
  margin: 0;
  letter-spacing: -2px;
  & span {
    font-size: 23px;
  }
  @media(max-width: 992px){
    font-size: 30px;
  }
`;
// =========== MODAL ===============
const TransitionOverlay = styled.div`
  position: absolute;
    margin: 0;
    height: calc( 100% - 48px);
    width: 100%;
    z-index: 90;
    pointer-events:none;
`;
const StyledModal = styled(Modal)`
  height: 100%;
  justify-content: flex-end;
  background: transparent;
  pointer-events:all;
  &.example-appear {
    transform: translateX(100%);
  }
  &.example-appear-active {
    transform: translateX(0px);
    transition: transform 500ms ease-out;
  }
  &.example-enter {
    transform: translateX(100%);
  }
  &.example-enter.example-enter-active {
    transform: translateX(0px);
    transition: transform 500ms ease-out;
  }
  &.example-leave {
    transform: translateX(0px);
  }
  &.example-leave.example-leave-active {
    transform: translateX(100%);
    transition: transform 500ms ease-in;
    }
    @media(max-width: 992px){
      margin-top: 75px;
      height: calc(100%-75px);
      justify-content: flex-end;
      align-items: flex-end;
    }
    @media(max-width: 768px){
      flex-flow: column;
    }
  & #modalInfoWrapper{
    max-width: calc(100% - 481px);
    width: calc(100% - 481px);
    height: 100%;
    max-height: 100%;
    align-self: center;
    justify-content: flex-start;
    align-items: center;
    border: none;
    @media(max-width: 992px){
      max-width: calc(100% - 256px);
      width: calc(100% - 256px);
      padding: 15px;
    }
    @media(max-width: 768px){
      height: calc(100% - 310px);
      max-width: 100%;
      width: 100%;
      padding: 15px;
    }
  }
  & #ChangePlanButtonWrapper {
    margin-top: 89px;
  }
  & #ChangePlanModalTitle{
    margin: 0;
  }
  & #ChangePlanModalCurrentPlan{
    margin-top: 13px;
  }
  & #accountPlanChangeModal{
    width: 707px;
    align-self: center;
    justify-content: flex-start;
    align-items: center;
    border: none;
    @media(max-width: 992px){
      padding: 15px;
    }
  }
`;
const ModalImage = styled.img`
  width: 81px;
  margin: 56px 0;
    @media(max-width: 768px){
      display: none;
    }
`;
const InnerContentWrapper= styled.div`
    max-width: 407px;
    align-self: center;
`;
const ModalContentTitle = styled.h3`
    font-size: 30px;
    font-weight: 200;
    margin: 0 0 30px;
    color: #E64C3C;
    text-align: left;
`;
const ModalContentInput = styled.input`
    height: 40px;
    padding: 10px;
    background: #D0D1D1;
    width: 100%;
    max-width: 407px;
    margin-bottom: 19px;
    border: none;
    &::placeholder{
      color: #9D9D9D;
      font-size: 14px;
    }
`;
const ModalH3 = styled.h3`
  font-size: 24px;
  font-weight: 200;
  color: #3B97D3;
  & span {
    color: #000000; 
  }
`;
const StyledDropdown= styled(DropdownStyled)`
  width: 100%;
  & #dropdownOpenerButton{
    margin: auto;
  }
  & #dropdownMenu{
    left: 18.5%;
    @media(max-width: 992px){  
      width: 60%;
      left: 20%;
    }
    @media(max-width: 500px){  
      width: 100%;
      left: 0;
    }
  }
`;
const ModalButtonWrapper = styled.div`
    width: 410px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 208px;
    @media(max-width: 500px){  
      width: 100%;
    }
    @media(max-width: 992px){  
      margin-bottom: 35px;
    }
`;
const ModalButton = styled(Button)`
    width: 191px;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 6px;
    background: #E64C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    @media(max-width: 500px){  
      width: 45%;
    }
`;
// =========== END MODAL ===============
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordModal: false,
      planModal: false,
    };
    this.handleModalUpdate = this.handleModalUpdate.bind(this);
  }
  handleModalUpdate(modal){
    this.setState(modal)
  };
  render() {
    return(
    <AccountWrapper>
      <SidebarNav />
      <AccountSubWrapper>
        <Title>Your Account</Title>
        <UserInfoWrapper>
          <UserInfo>
            <UserImage src={ProfPic} alt="User Avatar"/>
            <InnerUserInfo>
              <h2>{this.props.userInfo && this.props.userInfo.name ? this.props.userInfo.name : "Name"}</h2>
              <p>{this.props.userInfo && this.props.userInfo.email ? this.props.userInfo.email : "Username"}</p>
              <ModalLink onClick={()=>{this.handleModalUpdate({passwordModal: true,})}}>Change Password</ModalLink>
              <ModalLink onClick={()=>{this.handleModalUpdate({planModal: true,})}}>Change Subscription</ModalLink>
            </InnerUserInfo>
          </UserInfo>
          <PlanInfoWrapper>
            <PlanBox>
              <BoxImage src={UserPhoto} alt="plan icon"/>
              <BoxTitle>Current Plan</BoxTitle>
              <BoxData>Personal</BoxData>
            </PlanBox>
            <PlanBox>
              <BoxImage src={UploadPhotos} alt="plan icon"/>
              <BoxTitle>Current Storage</BoxTitle>
              <BoxData>0.0 GB<span> /20 GB</span></BoxData>
            </PlanBox>
            <PlanBox>
              <BoxImage src={ImageIcon} alt="plan icon"/>
              <BoxTitle>Total Photos</BoxTitle>
              <BoxData>100000</BoxData>
            </PlanBox>
          </PlanInfoWrapper>
        </UserInfoWrapper>
      </AccountSubWrapper>
      <ReactCSSTransitionGroup
            transitionName='example'
            transitionAppear={true}
            transitionEnter={true}
            transitionLeave={true}
            transitionEnterTimeout={500}
            transitionAppearTimeout={500}
            transitionLeaveTimeout={500}
            component={TransitionOverlay}
      >
      {this.state.passwordModal && 
        <StyledModal id="accountPasswordResetModal" passedHandle={this.handleModalUpdate} passedHandleArg={{passwordModal: false,}} PassedHeight={"100%"} >
          <ModalImage src={theLogo} alt="Remily Logo" />
          <InnerContentWrapper>
            <ModalContentTitle>Change password</ModalContentTitle>
            <ModalContentInput id="ModalCurrentPassword" placeholder="Current Password" />
            <ModalContentInput id="ModalNewPassword" placeholder="New Password" />
            <ModalContentInput id="ModalConfirmPassword" placeholder="Confirm Password" />
            <ModalButtonWrapper>
              <ModalButton onClick={()=>{this.handleModalUpdate({passwordModal: false,})}} text="Cancel" />
              <ModalButton onClick={()=>{this.handleModalUpdate({passwordModal: false,})}} text="Done" />
            </ModalButtonWrapper>
          </InnerContentWrapper>
        </StyledModal>
      }
      </ReactCSSTransitionGroup>
      <ReactCSSTransitionGroup
            transitionName='example'
            transitionAppear={true}
            transitionEnter={true}
            transitionLeave={true}
            transitionEnterTimeout={500}
            transitionAppearTimeout={500}
            transitionLeaveTimeout={500}
            component={TransitionOverlay}
      >
      {this.state.planModal && 
        <StyledModal id="accountPlanChangeModal" passedHandle={this.handleModalUpdate} passedHandleArg={{planModal: false,}} PassedHeight={"100%"}>
          <ModalImage src={theLogo} alt="Remily Logo" />
          <InnerContentWrapper>
            <ModalContentTitle id="ChangePlanModalTitle" >Change Plan</ModalContentTitle>
            <ModalH3 id="ChangePlanModalCurrentPlan">Current Plan: <span>Personal Plan</span></ModalH3>
            <ModalH3>Select New Plan</ModalH3>
            <StyledDropdown id="ChangePlanDropDown" />
            <ModalButtonWrapper id="ChangePlanButtonWrapper" >
              <ModalButton onClick={()=>{this.handleModalUpdate({planModal: false,})}} text="Cancel" />
              <ModalButton onClick={()=>{this.handleModalUpdate({planModal: false,})}} text="Done" />
            </ModalButtonWrapper>
          </InnerContentWrapper>
        </StyledModal>
      }
      </ReactCSSTransitionGroup>
    </AccountWrapper>
  )}
}
const mapStateToProps = state => {
  console.log("mapStateToProps", state);
  let returnedProps = {};
  if(state.apiReducer && state.apiReducer.userInfo){
    returnedProps.userInfo = state.apiReducer.userInfo;
  }; 
  return {...returnedProps};
}
export default connect(mapStateToProps, {}) (Account);