import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import io from "socket.io-client"
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { getCollections, getCollection, destroyCollection, getImage, getPredictiveSearch, getSearchItems, componantDispatcher, apiRequestPasswordUpdate, apiUserFullDataGetter } from '../actions/apiActions'
import { setCollectionIndex } from '../actions/CollectionActions'

import SidebarNav from '../components/SidebarNav';
import ColectionSidebar from '../components/ColectionSidebar';
import CollectionDisplayer from './CollectionDisplayer';

import DropzoneComponent from '../components/DropzoneComponent';
import ImageUploadModal from '../components/ImageUploadModal';


const StyledCollectionManagerWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  background-color: #DBDBDB;
`;
const StyledDropzoneWrapper = styled.div`
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row;
  @media (max-width: 992px) {
    flex-flow: column;
  }
`;
const DropzoneOverlay = styled.h1`
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
    background: #ffffff95;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
`;
class CollectionManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: '',
      editObj: {},
      editObjType: "",
      currentCollectionId: "",
      dropzoneActive: false,
      editIconVar: "none",
      ImageUploadModalActive: false,
      imagesToUpload: [],
      changeThisToTriggerReload: false,
    };
    this.onSearch = this.onSearch.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleCollectionDelete = this.handleCollectionDelete.bind(this);
    this.collectionIndexSetter = this.collectionIndexSetter.bind(this);
    this.editObjSetter = this.editObjSetter.bind(this);
    this.handleEditObjChange = this.handleEditObjChange.bind(this);
    this.getSpecificCollection = this.getSpecificCollection.bind(this);
    this.forceEditObjectToBeImage = this.forceEditObjectToBeImage.bind(this);
    this.setCurrentCollection = this.setCurrentCollection.bind(this);
    this.overlayHandler = this.overlayHandler.bind(this);
    this.collectionEditIconVarSetter = this.collectionEditIconVarSetter.bind(this);
    this.imageUploadOverlayHandler = this.imageUploadOverlayHandler.bind(this);
    this.apiSockets = this.apiSockets.bind(this);
    this.ifSearchSearchReverser = this.ifSearchSearchReverser.bind(this);
    
    
  }

  componentDidMount(){
    if(this.props.jwt){
      this.apiSockets(this.props.jwt)
      this.props.getCollections(this.props.jwt, 1, 15)
      this.props.apiUserFullDataGetter(this.props.jwt)
      return;
    }
  }
  apiSockets = () =>{
	var socket = io('http://remily-sockets.pxpqa.com');
    socket.on("auth-request", () => {
      socket.emit("login", this.props.jwt);
    });
    socket.on("image-uploaded", (data = {}) => {
      
      if(this.props.collection && data.collections.includes(this.props.collection.data.apikey)){
        console.log("Passed 1");

        if( (this.props.collection.data && 
          this.props.collection.data.page_details &&
          this.props.collection.data.page_details.current_page === 
          this.props.collection.data.page_details.total_pages) ||
          !this.props.collection.data.hasOwnProperty("page_details")){
            
            console.log("Passed 2");
            let updatedCollection = this.props.collection
            updatedCollection.data.images.push(data)
            updatedCollection.data.upload = true;
            let newDataForDispatcher = {
              type: "GET_API_COLLECTION",
              payload: updatedCollection,
            }
            this.props.componantDispatcher({type: "UNSET_COLLECTION", payload: {} })
            this.props.componantDispatcher(newDataForDispatcher)
            console.log("newDataForDispatcher", newDataForDispatcher);
        }
        this.setState({ state: this.state });
      }
    });
    socket.on("image-updated", (data) => {
      console.log("An image was updated!!!", data);
      for (let i = 0; i < this.props.collection.data.images.length; i++) {
        if(this.props.collection.data.images[i].apikey === data.apikey){
          let updatedCollection = this.props.collection
          updatedCollection.data.images[i] = data
          console.log("this.props.collection.data.images[i].collections[0]", this.props.collection.data.images[i].collections[0]);
          console.log("this.props.collection.data.apikey", this.props.collection.data.apikey);
          
          if(this.props.collection.data.images[i].collections[0] !== this.props.collection.data.apikey){
            updatedCollection.data.images.splice(i,1);
          }
          let newDataForDispatcher = {
            type: "GET_API_COLLECTION",
            payload: updatedCollection
          }
          this.props.componantDispatcher({type: "UNSET_COLLECTION", payload: {} })
          this.props.componantDispatcher(newDataForDispatcher)
        }
      }
      this.setState({ state: this.state });
    });
    socket.on("image-deleted", (data) => {
      console.log("An image was deleted!!!", data);
      for (let i = 0; i < this.props.collection.data.images.length; i++) {
        if(this.props.collection.data.images[i].apikey === data.apikey){
          let updatedCollection = this.props.collection
          updatedCollection.data.images.splice(i,1);
          let newDataForDispatcher = {
            type: "GET_API_COLLECTION",
            payload: updatedCollection
          }
          
          this.props.componantDispatcher({type: "UNSET_COLLECTION", payload: {} })
          this.props.componantDispatcher(newDataForDispatcher)
        }
        
      }
      this.setState({ state: this.state });
      // If image is in the store delete the image.
      // 
    });
    socket.on("collection-created", (data) => {
      console.log("A collection was created", data);
      
      let updatedCollections = this.props.collections
      let lastCollection = updatedCollections.data.collections[updatedCollections.data.collections.length-1]
      console.log("updatedCollections", updatedCollections);
      if(!lastCollection || lastCollection.apikey !== data.apikey ){
        let concatCollections = updatedCollections.data.collections.concat([data])
        updatedCollections.data.collections = concatCollections;
        let newDataForDispatcher = {
          type: "GET_API_COLLECTIONS",
          payload: updatedCollections
        }
        
        this.props.componantDispatcher(newDataForDispatcher)
        this.setState({ state: this.state });
      }
    });
    socket.on("collection-updated", (data) => {
      console.log("A collection was updated", data);
      for (let i = 0; i < this.props.collections.data.collections.length; i++) {
        if(this.props.collection && this.props.collections.data.collections[i].apikey === data.apikey){
          this.props.collections.data.collections.splice(i,1,data);
          let newDataForDispatcher = {
            type: "GET_API_COLLECTIONS",
            payload: this.props.collections
          }
          this.props.componantDispatcher(newDataForDispatcher)
        }
        if(this.props.collection && this.props.collection.data.apikey === data.apikey){
          console.log(data);
          let newDataForDispatcher = {
            type: "UNSET_COLLECTION",
            payload: {data:{}}
          }
          this.props.componantDispatcher(newDataForDispatcher)
        }
      }
      this.setState({ state: this.state });
    });
    socket.on("collection-deleted", (data) => {
      console.log("A collection was deleted", data);
      for (let i = 0; i < this.props.collections.data.collections.length; i++) {
        if(this.props.collections.data.collections[i].apikey === data.apikey){
          this.props.collections.data.collections.splice(i,1);
          let newDataForDispatcher = {
            type: "GET_API_COLLECTIONS",
            payload: this.props.collections
          }
          this.props.componantDispatcher(newDataForDispatcher)
          this.setState({ state: this.state });
          return
        }
        
      }
      this.setState({ state: this.state });
      // DO SOMETHING WITH THIS DATA!!!
    });
  }
  onSearch = (e, data) => {
    data.searchQuery=data.searchQuery.replace(/^\s+|\s+$/g, '');
    console.log("onSearch data", data);
    if(data.searchQuery !== "" && data.searchQuery.length > 0){
      this.props.getPredictiveSearch(this.props.jwt, data.searchQuery);
    }
    this.setState({
      textSearch: data.searchQuery, 
      changeThisToTriggerReload: !this.state.changeThisToTriggerReload
    });
  }
  handleSearchEnter = (e, data) => {
    data.value=data.value.replace(/^\s+|\s+$/g, '');
    console.log("handleSearchEnter data", data);
    this.onSearch(e, {searchQuery: data.value});
    if(data.value !== ""){
      this.props.getSearchItems(this.props.jwt, data.value);
    }
  }
  handleSearchClear = e => {
    this.props.getSearchItems(this.props.jwt, "");
  }
  handleCollectionDelete = () => {
    this.props.destroyCollection(this.props.collection.data.apikey, this.props.jwt)
    this.collectionIndexSetter(0);
  }
  collectionIndexSetter = index => {
    this.props.setCollectionIndex(index);
  }
  ifSearchSearchReverser(){
    if(this.props.searchBool){
      let newDataForDispatcher = {
        type: 'TOGGLE_SEARCH',
        payload: {},
      }
      this.props.componantDispatcher(newDataForDispatcher)
    }
  }
  editObjSetter=(editObj, editObjType)=>{
    // console.log(editObj);
    // console.log(editObjType);
    
    if(document.getElementById(`${this.state.editObj.apikey}-text`)){
      let lastTextElement = document.getElementById(`${this.state.editObj.apikey}-text`);
      lastTextElement.style.fontWeight = "300"
    }
    if(editObj.apikey && document.getElementById(`${editObj.apikey}-text`)){
      let textElement = document.getElementById(`${editObj.apikey}-text`);
      textElement.style.fontWeight = "bold"
    }
    this.setState({
      editObj,
      editObjType,
      textSearch: ""
    })
    if(editObjType==="image"){
      this.props.getImage(this.props.jwt, editObj.apikey);
    }
  }
  setCurrentCollection=(apiKey)=>{
    if(this.state.currentCollectionId && document.getElementById(this.state.currentCollectionId)){
      let oldCollection = document.getElementById(this.state.currentCollectionId);
      oldCollection.style.textDecoration = "none"
    }
    if(document.getElementById(`${apiKey}-text`)){
      let newCollection = document.getElementById(`${apiKey}-text`);
      newCollection.style.textDecoration = "underline"
      this.setState({currentCollectionId: `${apiKey}-text`})
    }
  }
  handleEditObjChange=(e)=>{
    if(e.target.id==="nameInput"){
      this.setState({editObj: {...this.state.editObj, name: e.target.value}});
    }
    if(e.target.id==="descInput"){
      this.setState({editObj: {...this.state.editObj, summary: e.target.value}});
    }
    if(e.target.id==="collectionInput"){
      this.setState({editObj: {...this.state.editObj, collectionName: e.target.value}});
    }
    if(e.target.id==="summary"){
      this.setState({editObj: {...this.state.editObj, summary: e.target.value}});
    }
    if(e.target.id==="EditLocationInput"){
      this.setState({editObj: {...this.state.editObj, EditLocationInput: e.target.value}});
    }
    if(e.target.id==="EditDateInput"){
      this.setState({editObj: {...this.state.editObj, EditDateInput: e.target.value}});
    }
  }
  forceEditObjectToBeImage=(imageObj)=>{
    this.setState({
      ...this.state,
      editObj: imageObj,
    })
  }
  getSpecificCollection=(jwt, collectionApiKey)=>{
    if(this.props.collection && this.props.collection.data && this.props.collection.data.apikey !== collectionApiKey){
      this.props.getCollection(jwt, collectionApiKey);
    }
    if(!this.props.hasOwnProperty("collection")){
      this.props.getCollection(jwt, collectionApiKey);
    }
  }
  overlayHandler=(activeBool)=>{
    this.setState({dropzoneActive: activeBool})
  }
  collectionEditIconVarSetter = (apiKey) => {
    this.setState({
      editIconVar: apiKey,
    })
  }
  
  imageUploadOverlayHandler=(activeBool, imagesToUpload)=>{
    console.log("imageUploadOverlayHandler imagesToUpload", imagesToUpload)
    this.setState({
      ImageUploadModalActive: activeBool,
      imagesToUpload: imagesToUpload,
    })
  }
  render() {
    return (
      <StyledCollectionManagerWrapper>
        <StyledDropzoneWrapper>
            <DropzoneComponent overlayHandler={this.overlayHandler} imageUploadOverlayHandler={this.imageUploadOverlayHandler}>
              { this.state.dropzoneActive &&  <DropzoneOverlay id="DropzoneOverlay" >
                  Select a collection then drop a photo here
              </DropzoneOverlay>}
              {this.props.collections && <SidebarNav /> }

              {this.props.collections && <ColectionSidebar getSpecificCollection={this.getSpecificCollection} textSearch={this.state.textSearch} onSearch={this.onSearch}  handleSearchEnter={this.handleSearchEnter}   handleSearchClear={this.handleSearchClear} setCollectionIndex={this.collectionIndexSetter} editObjSetter={this.editObjSetter} predictiveOptions={this.props.predictiveOptions || []} jwt={this.props.jwt} setCurrentCollection={this.setCurrentCollection} collectionEditIconVarSetter={this.collectionEditIconVarSetter} editIconVar={this.state.editIconVar} imageUploadOverlayHandler={this.imageUploadOverlayHandler} ifSearchSearchReverser={this.ifSearchSearchReverser} />}

              {this.props.collection && <CollectionDisplayer handleDelete={this.handleCollectionDelete} editObj={this.state.editObj} editObjType={this.state.editObjType} editObjSetter={this.editObjSetter} handleEditObjChange={this.handleEditObjChange} forceEditObjectToBeImage={this.forceEditObjectToBeImage} imageUploadOverlayHandler={this.imageUploadOverlayHandler}/> }
            </DropzoneComponent>
        </StyledDropzoneWrapper>
        <ReactCSSTransitionGroup
            transitionName='example'
            transitionAppear={true}
            transitionEnter={true}
            transitionLeave={true}
            transitionEnterTimeout={500}
            transitionAppearTimeout={500}
            transitionLeaveTimeout={500}
        >
          {this.state.ImageUploadModalActive && 
            <ImageUploadModal imageUploadOverlayHandler={this.imageUploadOverlayHandler} imagesToUpload={this.state.imagesToUpload} />
          }
        </ReactCSSTransitionGroup>
      </StyledCollectionManagerWrapper >
    );
  }
}
const mapStateToProps = state => {
  console.log("mapStateToProps", state);
  console.log("state.apiReducer.searchBool", state.apiReducer.searchBool);
  let returnedProps = {};
  
  if(state.apiReducer.searchBool){
        returnedProps.searchBool = state.apiReducer.searchBool;
        if(state.apiReducer && state.apiReducer.searchCollections  && state.apiReducer.searchCollections && state.apiReducer.searchCollections.data.collections){
          returnedProps.collections = state.apiReducer.searchCollections;
      };
      if(state.apiReducer && state.apiReducer.searchCollection){
          returnedProps.collection = state.apiReducer.searchCollection;
      }; 
  }
  if(!state.apiReducer.searchBool){
      if(state.apiReducer && state.apiReducer.collections  && state.apiReducer.collections && state.apiReducer.collections.data.collections){
          returnedProps.collections = state.apiReducer.collections;
      };
      if(state.apiReducer.collection){
          returnedProps.collection = state.apiReducer.collection;
      };
  }
  if(state.apiReducer && state.apiReducer.jwt){
    returnedProps.jwt = state.apiReducer.jwt;
  };  
  if(state.apiReducer && state.apiReducer.result && state.apiReducer.result.data.unassigned_images){
    returnedProps.unasignedImages = state.apiReducer.result.data.unassigned_images;
  };
  if(state.apiReducer && state.apiReducer.predictiveOptions){
    returnedProps.predictiveOptions = state.apiReducer.predictiveOptions;
  }; 
  return {...returnedProps};
}

export default connect(mapStateToProps, {apiRequestPasswordUpdate, apiUserFullDataGetter, componantDispatcher, getSearchItems, getPredictiveSearch, getImage, getCollections, getCollection, setCollectionIndex, destroyCollection}) (CollectionManager);
