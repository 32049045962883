import { injectGlobal } from 'styled-components';
import './assets/css/fonts.css';
// Overrides Semantic UI's 'overflow-x: hidden' rule so we can scroll on the horizontal timelines
injectGlobal`
  body {
    overflow-x: initial;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    min-height: 100vh;
  }
`;
