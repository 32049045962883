import React, { Component } from 'react';
import styled from 'styled-components';
 
import { Button } from 'styled-button-component';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from 'styled-dropdown-component';

const StyledButton = styled(Button)`
    max-width: 100%;
    width: 407px;
    background-color: white;
    color: ${props => props.activated ? "black" : "#9D9D9D"};
    border-radius: 0;
    border-color: #3B97D3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after{
        color: #3B97D3;
    }
    &:hover{
        background-color: white !important;
        color: #3B97D3;
        border-color: #3B97D3 !important;
    }
    &:focus{
        background-color: white !important;
        color: #3B97D3;
        border-color: #3B97D3 !important;
        box-shadow: none;
    }
    @media(max-width: 500px){  
      max-width: unset;
      width: 100%;
    }
`;
const StyledDropdownMenu = styled(DropdownMenu)`
    max-width: 100%;
    width: 407px;
    & div:nth-child(even){
        border-top: solid 1px #707070;
        border-bottom: solid 1px #707070;
    }
`;
const StyledDropdownItem = styled(DropdownItem)`
    margin: 0 8.5px;
    padding: 11px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover{
        background-color: #3B97D310 !important;
        color: #3B97D3;
        cursor: pointer;
    }
    & p {
        margin: 0 !important;
        cursor: pointer;
    }
`;
 
export default class DropdownUniversalStyled extends Component {
    constructor(props) {
        super();
        this.state = {
          hidden: true,
          itemSelected: false,
          selectedItem: "Please pick an option",
        };
        this.handleSelectedItem = this.handleSelectedItem.bind(this);
      }
     
      handleOpenCloseDropdown() {
          this.setState({
            hidden: !this.state.hidden,
          });
      }
      
      handleSelectedItem(item) {
        this.setState({
            selectedItem: item.name,
            itemSelected: true,
        });
        console.log(this.props);
        
        this.props.onChange(item.apikey, `${this.props.index}` || 'nope');
    }
    componentDidMount(){
        this.setState({
            selectedItem: this.props.selectedItem.name || "",
            itemSelected: !!this.props.selectedItem.name || false,
        })
    }
     
      render() {
        const { hidden } = this.state;
        return (
          <Dropdown className={this.props.className} {...this.props}>
            <StyledButton
              secondary
              dropdownToggle
              onClick={() => this.handleOpenCloseDropdown()}
              id="dropdownOpenerButton"
              activated={this.state.itemSelected}
            >
              {this.state.selectedItem}
            </StyledButton>
            <StyledDropdownMenu hidden={hidden} id="dropdownMenu">
                { this.props.items.data.collections.map((item, i)=>{
                    return <StyledDropdownItem key={`DropDownItem${i}`} onClick={()=>{this.handleSelectedItem(item); this.handleOpenCloseDropdown(); }}>{item.name}</StyledDropdownItem>
                })}
            </StyledDropdownMenu>
          </Dropdown>
        );
      }
    };