import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { post } from 'axios';
import { connect } from 'react-redux';
import { getCollection } from '../actions/apiActions';
import AddImageIcon from '../assets/img/add-image-icon.png';


const StyledDropzone = styled(Dropzone)`
    width: ${props => props.dzwidth ? props.dzwidth : '165px'};
    margin: ${props => props.dzmargin ? props.dzmargin : '0 0 10px 15px'};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &:hover{
        cursor: pointer;
    }
`;
const NoDragImg = styled.img`
    margin-left: '5%';
    -webkit-user-drag: none;
`;
class DropzoneClickComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
        };
        this.onImageDrop = this.onImageDrop.bind(this);
        this.fileUpload = this.fileUpload.bind(this)
    }
    onImageDrop(files) {
        // console.log("actual dropzone file", files);
        this.props.imageUploadOverlayHandler(true, files);
    }
    fileUpload(file){
        const formData = new FormData();
        formData.append('image',file)
        formData.append('name',file.name)
        formData.append('collection_id', this.props.collection.apikey)
        formData.append('summary','')
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.jwt}`
            },
        }
        return  post("https://remily-api.pxpqa2.com/images", formData, config)
    }
    render() {
        return(
            <StyledDropzone
                id="dropZone"
                multiple={true}
                accept="image/*"
                onDrop={this.onImageDrop}
                dzwidth={this.props.dzwidth}
                dzmargin={this.props.dzmargin}
                
            >
                {this.props.children ? this.props.children : <NoDragImg src={AddImageIcon} name="add" size="large" color="blue" alt="addNewImage" />}
            </StyledDropzone>
        )
    }
}
const mapStateToProps = state => {
    // console.log(state);
    let returnedProps = {};
    if(state.apiReducer.jwt){
      returnedProps.jwt = state.apiReducer.jwt;
    };    
    if(state.apiReducer.collection){
      returnedProps.collection = state.apiReducer.collection.data;
    }; 
    return {...returnedProps};
}
  
export default connect(mapStateToProps, {getCollection}) (DropzoneClickComponent);
