import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import qs from "query-string"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiUserLogin } from '../../actions/apiActions';
import { tryLogin } from './../../actions/auth';
import { logoutUser } from './../../actions/auth';
import { checkResetKey, apiPasswordUpdate, accountActivate, apiRequestPasswordUpdate, componantDispatcher } from './../../actions/apiActions';

import Navbar from '../../components/Navbar';
import Button from '../../components/Button2';
import Modal from '../../components/Modal';
import './Login.css';
import theLogo from '../../assets/img/remily-logo-simbol.png';
import LoadingGif from '../../assets/img/transparent-background-loading.gif'

const FullWidthWrapper = styled.div`
    width: 100%;
`;

const LoginNavbar = styled(Navbar)`
    width: 100%;
`;
// =========== MODAL ===============
const StyledModal = styled(Modal)`
  height: calc( 100% - 48px);
  & #modalInfoWrapper{
    padding: 62px 28px;
  }
  & #passwordResetSuccessLogo{
    margin-top: 40px;
  }
  & #usernameResetSuccessLogo{
    margin-top: 40px;
  }
  & #activatingYourAccountNowTitle{
    text-align: center;
    align-self: center;
  }
  & #activatingYourAccountNowButton{
    margin: auto;
  }
  
`;
const ModalContentInput = styled.input`
    height: 40px;
    padding: 10px;
    background: #D0D1D1;
    width: 100%;
    margin-bottom: 30px;
    border: none;
    &::placeholder{
      color: #9D9D9D;
      font-size: 14px;
    }
`;
const ModalButtonWrapper = styled.div`
    width: 410px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
`;
const ModalButton = styled(Button)`
    width: 191px;
    max-width: 45%;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 6px;
    background: #E64C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
`
const ModalContentTitle = styled.h3`
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin: 0 0 10px;
`;
const ModalOptionsTitle = styled.h3`
    text-align: center;
    width: 100%;
    margin: 0 0 10px;
`;
const ModalImage = styled.img`
    margin: 0 0 25px;
`;
const ModalOptionsWrapper = styled.div`
    width: 410px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    & #SecondInfoLink{
      margin-bottom: 98px;
    }
`;
const SuccessInfoWrapper = styled.div`
    text-align: left;
    max-width: 410px;
    margin: 0 0 40px;
`;

const SuccessModalA = styled.a`
    color: #E64C3C;
    font-weight: 700;
    font-size: 16px;
`;
const SuccessModalP = styled.p`
    font-size: 16px;
`;
const InfoLink = styled.p`
  &:hover{
    cursor: pointer;
  }
`;
// =========== END MODAL ===============

const CheckingLoadingGif = styled.img`
    margin: auto;
    width: 50px;
`;

const AdditionalLinksWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 40px 0 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-content: space-between;
  & p {
    font-size: 12px;
    color: #4E4E4E;
    margin-bottom: 6px;
  }
  & h1 {
    font-size: 12px;
    font-weight: 600;
    color: #3B97D3;
    margin: 0;
    &:hover{
      cursor: pointer;
    }
  }
  & #signInLinksAlignTextRight {
    text-align: right;
  }
  & #signInSignUpLink{
    text-align: left;
  }
`;
const StyledInput = styled.input`
    height: 40px;
    border: none;
    padding: 10px;
    margin-bottom: 16px;
    background: #cfd0d1;
    width: 100%;
    &::placeholder{
      color: #9D9D9D;
      font-size: 14px;
    }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      signUpBool: false,
      passVsUserResetFlag: "neither",
      stateRefresh: false,
    };
    // this.redirectToCurrentPath = this.redirectToCurrentPath.bind(this);
  }
  static propTypes = {
    error: PropTypes.object, // eslint-disable-line
  };

  static defaultProps = {
    error: {},
  };

  handleChange = (event, key) => {
    const { value } = event.target;

    this.setState({
      [key]: value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.apiUserLogin(this.state);
  };
  signUpBoolSetter(){
    this.setState({signUpBool: !this.state.signUpBool})
  }
  showPasswordResetModal(){
    let queryObj = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if(queryObj.event === "passwordupdate"){
      this.props.checkResetKey(queryObj.prkey);
      return true
    }
    return false
  }
  requestUpdatePassword=(email)=>{
    this.props.apiRequestPasswordUpdate(this.props.jwt, email)
  }
  componentDidMount(){
    this.props.componantDispatcher({type: "LOGIN_ERROR", payload: {}});
    this.props.componantDispatcher({type: "SET_JWT", payload: ''});
    if(this.props.user.isLoggedIn){
      this.props.logoutUser();
      return;
    }
    if(qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).event === "usercreate"){
      this.props.accountActivate(qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).activate_key)
    }
  }
  componentDidUpdate(){
    if(this.props.loginError && this.props.loginError.error && this.props.loginError.error === "no_payment_method"){
      this.props.history.push('/products');
    }
    if(this.props.passwordRequest === 'Email sent to user'){
      this.setState({passVsUserResetFlag: "passwordSuccess"})
      this.props.componantDispatcher({type: "SET_PASSWORD_REQUEST", payload: ''});
    }
    if(this.props.passwordRequest === 'User does not exist'){
      let inputToChange = document.getElementById("passUpdateEmail")
      inputToChange.setAttribute("style", "border: 1px solid red;")
      var text = document.createElement("P");
      text.innerHTML= "Email does not exist.";
      text.setAttribute("style", "margin-bottom: 15px;")
      inputToChange.parentNode.insertBefore(text, inputToChange.nextSibling)
      this.setState({stateRefresh: this.state.stateRefresh});
      this.props.componantDispatcher({type: "SET_PASSWORD_REQUEST", payload: ''});
    }
    if(this.props.jwt && !this.props.user.isLoggedIn && this.props.loginError && !this.props.loginError.error){
      this.props.tryLogin(this.state);
      this.props.history.push('/app/photos');
    }
  }
  render() {
    return (
      <FullWidthWrapper>
        <LoginNavbar/>
        <div className="login">
          {qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).event === "usercreate" && 
            <StyledModal id="activatingAccoutModal">
              <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" />
              <ModalContentTitle id="activatingYourAccountNowTitle">Activating Your Account Now.</ModalContentTitle>
              <ModalButtonWrapper>
                <ModalButton  id="activatingYourAccountNowButton" onClick={()=>this.props.history.push(`/login`)} text="OK!" />
              </ModalButtonWrapper>
              {this.props.loginError.issue &&
                <div>
                  <br/>
                  Error: {this.props.loginError.issue}
                </div>
              }
            </StyledModal>
          }
          {/* START OF SIGN IN PASSWORD RESET */}
          {qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).event === "requestUpdatePassword" && this.state.passVsUserResetFlag==="neither" && 
            <StyledModal id="passwordResetModal2">
              <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" />
              <ModalOptionsTitle>Please select one of the following:</ModalOptionsTitle>
              <ModalOptionsWrapper>
                <InfoLink onClick={()=>{this.setState({passVsUserResetFlag: "username"})}}>Find Username</InfoLink>
                <InfoLink id='SecondInfoLink' onClick={()=>this.setState({passVsUserResetFlag: "password"})}>Reset Password</InfoLink>
              </ModalOptionsWrapper>
            </StyledModal>
          }
          {/* RESET PASSWORD */}
          {qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).event === "requestUpdatePassword" && this.state.passVsUserResetFlag==="password" && 
            <StyledModal id="passwordResetModalInput">
              <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" />
              <ModalContentTitle>Enter email address for password reset.</ModalContentTitle>
              <ModalContentInput id="passUpdateEmail" placeholder="Email" />
              <ModalButtonWrapper>
                <ModalButton onClick={()=>{ this.setState({passVsUserResetFlag: "neither"})}} text="Cancel" />
                <ModalButton onClick={()=>{this.requestUpdatePassword(document.getElementById("passUpdateEmail").value);}} text="Send" />
              </ModalButtonWrapper>
            </StyledModal>
          }
          {/* RESET PASSWORD SUCCESS*/}
          {qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).event === "requestUpdatePassword" && this.state.passVsUserResetFlag==="passwordSuccess" && 
            <StyledModal id="passwordResetModalSuccess">
              <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" id="passwordResetSuccessLogo" />
              <SuccessInfoWrapper>
                <SuccessModalP>Please check your email for instructions on resetting your password.</SuccessModalP>
                <SuccessModalA href="/login" onClick={()=>{this.setState({passVsUserResetFlag: "neither"})}}>- Remily Customer Support</SuccessModalA>
              </SuccessInfoWrapper>
            </StyledModal>
          }
          {/* RESET PASSWORD ENTER NEW PASSWORD */}
          { this.showPasswordResetModal() ? 
            <StyledModal noExit={true} id="signInPasswordResetModal">
              <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" />
              <ModalContentTitle>Please enter your new Password.</ModalContentTitle>
              <ModalContentInput id="passUpdateEmail" placeholder="new password"/>
              <ModalButtonWrapper>

                <ModalButton onClick={()=>{this.props.history.push(`/login`); this.setState({passVsUserResetFlag: "neither"})}} text="Cancel" />

                <ModalButton onClick={()=>{this.props.apiPasswordUpdate(qs.parse      (this.props.location.search, { ignoreQueryPrefix: true }).prkey, document.getElementById("passUpdateEmail").value); this.props.history.push(`/login`)}} text="Change Password" />

              </ModalButtonWrapper>
            </StyledModal> 
          : this.props.goodReset && 
            <StyledModal noExit={true}>
              <ModalContentTitle>Checking your password reset key.</ModalContentTitle>
              <CheckingLoadingGif src={LoadingGif}/>
            </StyledModal>
          }
          {/* FIND USERNAME */}
          {qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).event === "requestUpdatePassword" && this.state.passVsUserResetFlag==="username" && 
            <StyledModal id="usernameResetModal">
              <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" />
              <ModalContentTitle>Enter email address to find out your username.</ModalContentTitle>
              <ModalContentInput id="userUpdateEmail" placeholder="Email" />
              <ModalButtonWrapper>
                <ModalButton onClick={()=>{this.setState({passVsUserResetFlag: "neither"})}} text="Cancel" />
                <ModalButton onClick={()=>{this.setState({passVsUserResetFlag: "usernameSuccess"})}} text="Send" />
              </ModalButtonWrapper>
            </StyledModal>
          }
          {/* FIND USERNAME SUCCESS*/}
          {qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).event === "requestUpdatePassword" && this.state.passVsUserResetFlag==="usernameSuccess" && 
            <StyledModal id="usernameResetModalsuccess">
              <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" id="usernameResetSuccessLogo"/>
              <SuccessInfoWrapper>
                <SuccessModalP>Please check your email for instructions on your username.</SuccessModalP>
                <SuccessModalA href="/login" onClick={()=>{this.props.history.push(`/app/photos`); this.setState({passVsUserResetFlag: "neither"})}}>- Remily Customer Support</SuccessModalA>
              </SuccessInfoWrapper>
            </StyledModal>
          }
    
          <div className="login__box">
            <form className="login__form">
              <img src={theLogo} alt="Remily Logo" className="login__logo" />
              <StyledInput className="login__input" placeholder="Email" type="text" name="email" value={this.state.email} onChange={event => this.handleChange(event, 'email')} />
              <StyledInput className="login__input" placeholder="Password" type="password" name="password" value={this.state.password} onChange={event => this.handleChange(event, 'password')} />
              <div className="login__error-wrap">
                <p className="login__errors">{(this.props.loginError &&this.props.loginError.error)?this.props.loginError.error:""}</p>
              </div>
              <div className="login__buttons">
                <Button text="Log-in" onClick={this.handleSubmit} />
                <AdditionalLinksWrapper>
                  <div>
                    <p>Don't have an account?</p>
                    <Link to="/sign-up" id="signInSignUpLink">
                      <h1>Sign Up</h1>
                    </Link>
                  </div>
                  <div id="signInLinksAlignTextRight">
                    <p>Forgot username/password?</p>
                    <Link to="/login?event=requestUpdatePassword">
                      <h1>Reset Password</h1>
                    </Link>
                  </div>
                </AdditionalLinksWrapper>
              </div>
            </form>
          </div>
        </div>
      </FullWidthWrapper>
    );
  }
}

function mapStateToProps(state) {
  //console.log(state);
  
  let returnedProps = {};
  if(state.user){
    returnedProps.user = state.user;
  };
  if(state.apiReducer.jwt){
    returnedProps.jwt = state.apiReducer.jwt;
  }; 
  if(state.apiReducer.login){
    returnedProps.login = state.apiReducer.login;
  };
  if(state.apiReducer && state.apiReducer.loginError){
      returnedProps.loginError = state.apiReducer.loginError;
  }; 
  if(state.apiReducer && state.apiReducer.passwordRequest){
    returnedProps.passwordRequest = state.apiReducer.passwordRequest;
  };
  returnedProps.goodReset = state.apiReducer.goodReset;
  return {...returnedProps};
}

export default connect(mapStateToProps, {componantDispatcher, apiRequestPasswordUpdate, accountActivate, apiPasswordUpdate, checkResetKey, apiUserLogin, tryLogin, logoutUser})(Login);
