import React from 'react';
import styled from 'styled-components';
import { Icon, Dropdown } from 'semantic-ui-react'

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 37px 0 15px 0;
  width: 100%;
  @media (max-width: 992px) {
    flex: 0 1 68px;
  }
  & .ui.dropdown:not(.button)>.default.text {
    color: #9D9D9D !important;
    font-size: 14px !important;
  }
`;
const IconStyle = styled(Icon)`    
  position: relative;
  top: 11px;
  bottom: 0;
  right: 2.5%;
  line-height: 1;
  z-index: 1;
`
const StyledDropdown = styled(Dropdown)`
  display: block !important;
  width: 85% !important;
  min-width: 0 !important;
  background-color: #FFFFFF !important;
  border: none !important;
  border-radius: 0 !important;
  & i::before{
    color: black !important;
    font-size: 26px !important;
  }
`;

const SearchCollections = ({ onSearch, textSearch, predictiveOptions, handleSearchEnter, handleSearchClear, jwt, searchBool}) => {
  
    return (
      <SearchBarContainer>
        {searchBool && <IconStyle link name="close" onClick={handleSearchClear} /> }
        <StyledDropdown placeholder='Search' fluid search selection multiple={false} options={predictiveOptions} onChange={handleSearchEnter} onSearchChange={onSearch} value={textSearch} onClick={()=>onSearch(jwt, {searchQuery:""})} onBlur={()=>onSearch(jwt, {searchQuery:""})} />
      </SearchBarContainer>
    );
};

export default SearchCollections;