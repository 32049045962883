const colors = {
  blue1: '#2C3E50',
  blue2: '#2880B9',
  blue3: '#3B97D3',
  gray1: '#BCBEC0',
  gray2: '#929497',
  gray3: '#F1F2F2',
  gray4: '#EDF0F1',
  white: '#ffffff',
  orange: '#E64C3C'
};

const typography = {
  display1: `
    font-size: 35px;
    font-weight: 400;
    line-height: 36px;
  `,
  display2: `
    font-size: 25px;
    font-weight: 400;
    line-height: 25px;
  `,
  body1: `
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
  `,
  body2: `
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  `,
  caption: `
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
  `
}

module.exports = {
  colors,
  typography
}