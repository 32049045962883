const initialState = {
    goodReset: false,
    predictiveOptions:[],
    loginError: {},
    newUserNoPay: false,
    paymentMethod: "",
    selectedPlanId: "",
    searchBool: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GET_API_DATA':
            return {
                ...state,
                result: action.payload,
            }
        case 'SET_JWT':
            return {
                ...state,
                jwt: action.payload,
            }
        case 'CREATION_LOGIN':
            return {
                ...state,
                jwt: action.payload,
                newUserNoPay: true,
            }
        case "LOGIN_ERROR":
            return {
                ...state,
                loginError: action.payload,
            }
        case "SET_PASSWORD_REQUEST":
            return {
                ...state,
                passwordRequest: action.payload,
            };
        case 'GET_API_COLLECTIONS':
            console.log(action.payload);

            if(state.collections && state.collections.data.page_details.current_page < state.collections.data.page_details.total_pages){
                return {
                    ...state,
                    collections: action.payload,
                }
            }
            if(!state.hasOwnProperty("collections")){
                return {
                    ...state,
                    collections: action.payload,
                }
            }
            return {
                ...state,
            }
            
        case 'TOGGLE_SEARCH':
            console.log(state);
            return {
                ...state,
                searchBool: !state.searchBool,
            }
            
        case 'SET_SEARCH_COLLECTIONS':
            console.log(state);
            return {
                ...state,
                searchCollections: action.payload,
            }
            
        case 'SET_SEARCH_COLLECTION':
            console.log(state);
            return {
                ...state,
                searchCollection: action.payload,
            }
        case 'GET_API_COLLECTION':
            if(action.payload.data.upload){
                if(action.payload.data.upload){
                    action.payload.data.upload = false;
                }
                return {
                    ...state,
                    collection: action.payload,
                }
            }
            if(state.collection && state.collection.data &&!state.collection.data.hasOwnProperty("page_details")){
                return {
                    ...state,
                    collection: action.payload,
                }
            }
            if(state.collection && state.collection.data && state.collection.data.apikey && state.collection.data.apikey !== action.payload.data.apikey){
                return {
                    ...state,
                    collection: action.payload,
                }
            }
            if(state.collection && state.collection.data.page_details.current_page < state.collection.data.page_details.total_pages){
                var newCollection = {...action.payload}
                newCollection.data.images = state.collection.data.images.concat(action.payload.data.images)
                return {
                    ...state,
                    collection: newCollection ? newCollection : action.payload,
                }
            }
            if(!state.hasOwnProperty("collection") || typeof state.collection === "undefined"){
                return {
                    ...state,
                    collection: action.payload,
                }
            }
            return {
                ...state,
            }
        case 'UNSET_COLLECTION':
            console.log("unsetting collection");
            
            return {
                ...state,
                collection: undefined,
            }
        case 'SET_IMAGE':
            return {
                ...state,
                apiEditObj: action.payload,
            }
            
        case 'CLEAR_PREDICTIVE_SEARCH':
        return {
            ...state,
            predictiveOptions: [],
        }
        case 'SET_PREDICTIVE_SEARCH':
            return {
                ...state,
                predictiveOptions: action.payload,
            }
        case 'GOOD_RESET_SETTER':
            return {
                ...state,
                goodReset: action.payload,
            }
        case 'SET_PAYMENT_METHOD':
            return {
                ...state,
                paymentMethod: action.payload,
            }
            
        case 'SET_PLAN_ID':
        console.log('SET_PLAN_ID', action.payload)
            return {
                ...state,
                selectedPlanId: action.payload,
            }
            
        case 'SET_USER_INFO':
        console.log('SET_USER_INFO', action.payload)
            return {
                ...state,
                userInfo: action.payload,
            }
            
        case 'LOGOUT_USER':
            return {}
        default:
        return state
            }
}