// import getClient from '../services/getClient';


const receiveUser = user => ({
  type: "RECEIVE_USER",
  data: user,
});

export const logoutUser = () => ({
  type: "LOGOUT_USER",
});

const loginError = error => ({
  type: 'LOGIN_ERROR',
  payload: error,
})
export const tryLogin = credentials => async dispatch => {
  // const client = getClient();
  try {
    // const response = await client.login(credentials);
    dispatch(receiveUser(credentials));
  } catch (error) {
    console.log(error);
    dispatch(loginError(error));
  }
};
