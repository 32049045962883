import React, { Fragment } from 'react';
import styled from 'styled-components';

import Container from '../components/layout/Container';
import InfoCard from '../components/InfoCard';
import Navbar from '../components/Navbar';
import LandingHeroSlider from '../components/LandingHeroSlider';

import { colors } from '../assets/js/StyleConstants';
import createAccountImage from '../assets/img/icon-1.svg';
import uploadPhotos from '../assets/img/icon-3.svg';
import shareWithFamily from '../assets/img/icon-4.svg';

const HomePageWrapper = styled.div`
  width: 100%;
  background: ${colors.gray4};
`;

const StyledContainer = styled(Container)`
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
`;
const InfoCardsWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 55px 40px 40px 40px;
  justify-content: space-between;
  align-items: center;
  background: ${colors.gray4};
  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0;
  }
`;
const StyledhomeInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
      padding-top: 25px;
  }
`;
const StyledInfoImg = styled.img`

`;
const renderInfoCards = () => {
  const LineConnector = styled.div`
    background: #4E4E4E29;
    height: 120px;
    width: 3px;
    margin: 0 25px;
    @media (max-width: 992px) {
      height: 3px;
      width: 120px;
      margin: 25px 0;

    }
  `;

  const StyledInfoCard = styled(InfoCard)`
    background-color: transparent;
    text-align: center;
    padding-top: 25px;
    height: 100%;
    margin-bottom: 0;
    & img{
    }
    & h1 {
      color: #E64C3C;
      font-size: 28px;
    }
    & p {
      color: #4E4E4E;
      line-height: 22px;
      font-weight: 400;
    }
    &:last-child {
      margin: 0;
    }

    @media (max-width: 992px) {
      max-width: 350px;
      width: 100%;
    }
  `;

  const cards = [{
    image: createAccountImage,
    title: 'Create an account',
    description: ''
  }, {
    image: uploadPhotos,
    title: 'Upload Photos',
    description: ''
  }, {
    image: shareWithFamily,
    title: 'Share With Family',
    description: ''
  }]

  return cards.map((card, idx) => {
    return (
      <Fragment key={`infoCard${idx}`}>
      <StyledhomeInfoWrapper>
        <StyledInfoImg src={card.image} />
        <StyledInfoCard title={card.title} description={card.description} />
      </StyledhomeInfoWrapper>
        {idx !== (cards.length - 1) &&
          <LineConnector />
        }
      </Fragment>
    );
  });
}
const css = `
    .slick-next {
      left: 0 !important;
      display: none !important;
    }
    .slick-dots {
        bottom: 15px !important;
        right: 18px;
        left: unset;
        width: auto !important;
        color: white;
    }
    .slick-dots li button:before {
        color: white !important;
        font-size: 11px !important;
    }
    .slick-dots li {
      margin: 0 !important;
    }
    @media (min-width: 992px){
      .slick-dots li {
        margin: 0 5px !important;
        height: 25px;
        width: 25px;
      }
    }
`;

export const HomePageBlock = () => (
  <HomePageWrapper>
    <style>{css}</style>

    <Navbar />
    <LandingHeroSlider/>

    <StyledContainer>
      <InfoCardsWrap>
        {renderInfoCards()}
      </InfoCardsWrap>
    </StyledContainer>
  </HomePageWrapper>
);
