import React, { Component } from 'react';
import styled from 'styled-components';

import Navbar from '../components/Navbar';
import theLogo from '../assets/img/complete-smile.png';

const ProductsWrapper = styled.div`
    width: 100%;
`;

const ProductsContentWrapper = styled.div`
    width: 100%;
    padding: 0 25px;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

const TopLogo = styled.img`
    margin: 121px auto auto auto;
    width: 128px;
    @media(max-width: 992px){
      margin: 30px auto auto;
    }
`;
const InfoWrapper = styled.div`
    max-width: 407px;
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    & h1 {
      color: #E64C3C;
      font-size: 30px;
      font-weight: 200;
      margin: 0 0 9px;
    }
    & h2 {
      color: #E64C3C;
      font-size: 18px;
      font-weight: 900;
      margin: 30px 0 0;
      & span {
          color: black;
          font-size: 22px;
          font-weight: 400;
      }
    }
    & p {
        margin: 0 0 10px 0;
        color: black;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
    }
    @media(max-width: 992px){
      height: 260px;
    }
`;

class Complete extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

  render() {
    return (
      <ProductsWrapper>
        <Navbar/>
        <ProductsContentWrapper>
            <TopLogo src={theLogo} alt="Remily Logo"/>
            <InfoWrapper>
                <h1>Complete!</h1>
                <p>Please check your email with directions and important information about your account.</p>
                <p>Thank you for picking Remily as your choice to savor the important memories in your life.</p>
                <h2><span>-</span> Remily</h2>
            </InfoWrapper>
        </ProductsContentWrapper>
      </ProductsWrapper>
    );
  }
}

export default Complete
