import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { componantDispatcher } from '../actions/apiActions'

import Navbar from '../components/Navbar';
import Modal from '../components/Modal';
import Button from '../components/Button2';
import '../containers/Login/Login.css';
import { createUser } from '../actions/apiActions';
import theLogo from '../assets/img/remily-logo-simbol.png';


const FullWidthWrapper = styled.div`
    width: 100%;
`;
const LoginNavbar = styled(Navbar)`
    width: 100%;
`;
const SignUpWrapper = styled.div`
    width: 100%;    
    background: #ffffff;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    & *.invalid{
        border: red 1px solid;
    }
`;
const SignUpLogo = styled.img`
    width: 81.5px;
    margin: 25px 0 30px 0;
`;
const SignUpDisplayBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    width: 100%;
    border-radius: 5px;
    @media (max-width: 992px) {
        width: 90%;
        padding: 10px;
    }
`;
const SignUpTitle = styled.h1`
    color: #E64C3C;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: -.5px;
    margin: 5px 0 10px !important;
    width: 100%;
    max-width: 407px;
`;
const SignUpSubTitle = styled.p`
    color: black;
    font-size: 15px;
    width: 100%;
    max-width: 407px;
    margin: 0 0 20px;
`;
const SignUpForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 407px;
    width: 100%;
`;
const SignUpButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0 30px 0;
`;

const StyledButton = styled(Button)`
    width: 191px;
    max-width: 45%;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 6px;
    background: #E64C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
`;
const StyledInput = styled.input`
    height: 40px;
    border: none;
    padding: 10px 10px 10px 16px;
    margin-bottom: 19px;
    background: #D0D1D1;
    width: 100%;
    &::placeholder{
      color: #9D9D9D;
      font-size: 14px;
    }
`;
const SignUpBottomSubTitle = styled.p`
    color: #4E4E4E;
    font-size: 12px;
    margin-bottom: 5px;
`;

const SignUpLink = styled.a`
    color: #3B97D3;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 25px !important;
    &:hover{
        cursor: pointer;
    }
`;
// =========== ERROR MODAL ===============

const TransitionOverlay = styled.div`
    position: fixed;
    margin: 0;
    height: calc(100% - 71px);
    width: 100%;
    z-index: 90;
    pointer-events:none;
`;
const StyledModal = styled(Modal)`
height: calc( 100% - 48px);
    justify-content: flex-end;
    align-items: flex-start;
    background: transparent;
    pointer-events:all;
    &.example-appear {
        transform: translateX(100%);
    }
    &.example-appear-active {
        transform: translateX(0px);
        transition: transform 500ms ease-out;
    }
    &.example-enter {
        transform: translateX(100%);
    }
    &.example-enter.example-enter-active {
        transform: translateX(0px);
        transition: transform 500ms ease-out;
    }
    &.example-leave {
        transform: translateX(0px);
    }
    &.example-leave.example-leave-active {
        transform: translateX(100%);
        transition: transform 500ms ease-in;
    }
    & #modalInfoWrapper{
        padding: 25px;
        align-self: flex-start;
        min-width: 230px;
    }
    & #usernameResetSuccessLogo{
        margin-top: 40px;
    }
`;
const ModalButton = styled(Button)`
    width: 191px;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-bottom: 6px;
    background: #E64C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
`;
const ModalImage = styled.img`
    margin: 15px;
`;
const SuccessModalP = styled.p`
    font-size: 16px;
`;
// =========== END ERROR MODAL ===============
class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            username: '',
            password: '',
            signUpBool: false,
            modalFlag: "",
            countTracker: 0,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
    handleChange = (event, key) => {
    const { value } = event.target;

        this.setState({
            [key]: value,
        });
    };

    handleSubmit = e => {
        console.log("signup this.state", this.state);
        this.props.createUser(this.state);
    };
    unsetInvalid(e){
        console.log("the element instance", e.target);
        
        if(e.target.classList.contains('invalid')){
            let elements = document.getElementsByClassName("invalid");
            if(elements.length>0){
                for (let i = 0; i < elements.length; i++) {
                    elements[i].classList.remove("invalid");
                }
            }
        }
    }
    componentDidMount(){
        if(this.props.loginError && this.props.loginError.error && this.props.loginError.error === "no_payment_method"){
            this.props.history.push('/products');
        }
        else{
            this.props.componantDispatcher({type: "LOGIN_ERROR", payload: {}});
        }
    }
    componentDidUpdate(prevProps){
        if(this.props.jwt){
            this.props.history.push('/products');
        }
        if(this.state.countTracker === 0){
            console.log("fresh");
        }
        if(this.props.loginError && this.props.loginError.error && this.props.loginError.error === "Email has already been taken" && this.state.countTracker < 1){
            let element = document.getElementById("SignUpEmailInput");
            element.classList.add("invalid");
            this.setState({
                modalFlag: "emailInUse",
                countTracker: this.state.countTracker+1
            })
        }
    }
    render() { 
        return ( 
            <FullWidthWrapper>
                <LoginNavbar/>
                <SignUpWrapper>
                    <SignUpLogo
                        src={theLogo}
                        alt="Remily Logo"
                    />
                    <SignUpDisplayBox>
                        <SignUpTitle>Let’s gets started</SignUpTitle>
                        <SignUpSubTitle>Complete the form to begin building your memories for the future</SignUpSubTitle>
                        <SignUpForm>
                            <StyledInput placeholder="Name" type="text" name="name" value={this.state.name} onChange={event => this.handleChange(event, 'name')} onClick={(e)=>{this.unsetInvalid(e)}} />
                            <StyledInput id="SignUpEmailInput" placeholder="Email" type="text" name="email" value={this.state.email} onChange={event => this.handleChange(event, 'email')} onClick={(e)=>{this.unsetInvalid(e)}} />
                            <StyledInput placeholder="Username" type="text" name="username" value={this.state.username} onChange={event => this.handleChange(event, 'username')} onClick={(e)=>{this.unsetInvalid(e)}} />
                            <StyledInput placeholder="Password" type="password" name="password" value={this.state.password} onChange={event => this.handleChange(event, 'password')} onClick={(e)=>{this.unsetInvalid(e)}} />
                            <SignUpButtonsWrapper>
                                <StyledButton text="Cancel"  onClick={() => this.props.history.push('/login')}/>
                                <StyledButton text="Next" onClick={() => this.handleSubmit()} />
                            </SignUpButtonsWrapper>
                            <SignUpBottomSubTitle>Have an account?</SignUpBottomSubTitle>
                            <SignUpLink href="/login">Sign In</SignUpLink>
                        </SignUpForm>
                    </SignUpDisplayBox>
                </SignUpWrapper>
                {/* ===========NOTIFICATION MODALS=========== */}
                
                <ReactCSSTransitionGroup
                        transitionName='example'
                        transitionAppear={true}
                        transitionEnter={true}
                        transitionLeave={true}
                        transitionEnterTimeout={500}
                        transitionAppearTimeout={500}
                        transitionLeaveTimeout={500}
                        component={TransitionOverlay}
                >
                    {this.state.modalFlag==="emailInUse" && 
                        <StyledModal id="signUpEmailInUseModalWrapper" passedHandle={()=>{this.setState({modalFlag: ""}); this.props.componantDispatcher({type: "LOGIN_ERROR", payload: {} }); this.setState({countTracker: 0})}}>
                        <ModalImage src={theLogo} alt="Remily Logo" className="login__logo" id="passwordResetSuccessLogo" />
                        <SuccessModalP>{this.props.loginError.error}</SuccessModalP>
                        <ModalButton onClick={()=>{this.setState({modalFlag: ""}); this.props.componantDispatcher({type: "LOGIN_ERROR", payload: {} }); this.setState({countTracker: 0})}} text="Clear" />
                        </StyledModal>
                    }
                </ReactCSSTransitionGroup>
            </FullWidthWrapper>
         );
    }
}
 
const mapStateToProps = state => {
    console.log(state);
    let returnedProps = {};

    if(state.apiReducer && state.apiReducer.jwt){
        returnedProps.jwt = state.apiReducer.jwt;
    };
    if(state.apiReducer && state.apiReducer.loginError){
        returnedProps.loginError = state.apiReducer.loginError;
    };
    
    return {...returnedProps};
}
  
export default connect(mapStateToProps, {createUser, componantDispatcher}) (SignUp);