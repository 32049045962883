import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class ProtectedRoute extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.object, // eslint-disable-line
  };

  static defaultProps = {
    error: {},
  };

  render() {
    const { component: Component, pending, user, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          if (pending) return <div>Loading...</div>;
          return user.isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />;
        }}
      />
    );
  }
}

export default connect(state => ({
  user: state.user,
}))(ProtectedRoute);
