import axios from 'axios'

export const componantDispatcher = newData => dispatch =>{
    // console.log("newData", newData);
    
    dispatch({
        type: newData.type,
        payload: newData.payload,
     })
}
const setJwt = jwt => dispatch => {
    dispatch({
       type: 'SET_JWT',
       payload: jwt,
    })
};
const loginError = error => dispatch => {
    dispatch({
        type: 'LOGIN_ERROR',
        payload: error,
     })
}
const creationLogin = jwt => dispatch => {
    dispatch({
        type: 'CREATION_LOGIN',
        payload: jwt,
     })
}
const receiveData = apiData => dispatch => {
    dispatch({
        type: 'GET_API_DATA',
        payload: apiData,
    })
};
const setPredictiveSearch = apiData => dispatch => {
    dispatch({
        type: 'CLEAR_PREDICTIVE_SEARCH',
        payload: apiData,
    }); 
    dispatch({
        type: 'SET_PREDICTIVE_SEARCH',
        payload: apiData,
    })
}
export const apiUserLogin = (user) => {
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: `http://remily-api.pxpqa.com/login`,
                data: {
                    "email": user.email,
                    "password": user.password
                }
            })
            .then(function (response) {
                dispatch(loginError({error:""}))
                if(response.data.error){
                    if(response.data.error.no_payment_method){
                        dispatch(loginError({error: "no_payment_method"}))
                    }
                }
                dispatch(setJwt(response.data.access_token)) ;
            }).catch(error => {
                dispatch(loginError({error: error.response.data.error.user_authentication}))
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const createUser = (user) => {
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: `http://remily-api.pxpqa.com/users`,
		headers: {'Access-Control-Allow-Origin': '*',},
                data: {
                    name: user.name,
                    email: user.email,
                    password: user.password,
                }
            })
            .then(function (response) {
                console.log("signup response",response)
                dispatch(setJwt(response.data.access_token))
                dispatch(creationLogin(response.data.access_token))
            }).catch(error => {
                console.log(error);
                dispatch(loginError(error.response.data))
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const apiUserDataGetter = (jwt) => {
    const Url = `http://remily-api.pxpqa.com/users`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try{
            await axios.get(Url, {'headers': requestData})
            .then(function (response) {
                dispatch(receiveData({...response, 'role': 'user'}));
            }).catch(error => {
                console.log("Profile error", error)
            });
            
        }catch(err) {
            console.log('apiUserDataGetter Try error', err)
        }
    }
};

export const apiUserFullDataGetter = (jwt) => {
    const Url = `http://remily-api.pxpqa.com/users/full`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try{
            await axios.get(Url, {'headers': requestData})
            .then(function (response) {
                dispatch({
                    type: 'SET_USER_INFO',
                    payload: response.data,
                });;
            }).catch(error => {
                console.log("Profile error", error)
            });
            
        }catch(err) {
            console.log('apiUserFullDataGetter Try error', err)
        }
    }
};

export const accountActivate = (activateKey) => {
    const Url = `http://remily-api.pxpqa.com/confirm/${activateKey}`
    return async (dispatch) => {
        try{
            await axios.get(Url)
            .then(function (response) {
                console.log(response);
                
            }).catch(error => {
                console.log('apiUserDataGetter Try error', error)
                dispatch(loginError({issue: error.response.statusText}))
            });
            
        }catch(err) {
            console.log('apiUserDataGetter Try error', err)
        }
    }
};
export const apiRequestPasswordUpdate = (jwt, email) => {
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: `http://remily-api.pxpqa.com/request_password_reset`,
                headers: requestData,
                data: {
                    "email": email,
                }
            })
            .then(function (response) {
                console.log(response);
                if(response.data.success){
                    dispatch(componantDispatcher({type: "SET_PASSWORD_REQUEST", payload: response.data.success}));
                }
            }).catch(error => {
                console.log("error", error.response);
                console.log(error.response.data.error);
                dispatch(componantDispatcher({type: "SET_PASSWORD_REQUEST", payload: error.response.data.error}));
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};

export const checkResetKey = ( resetKey) => {
    console.log("fired");
    
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: `http://remily-api.pxpqa.com/verify_reset_key/${resetKey}`,
            })
            .then(function (response) {
                console.log(response);
                
                dispatch({
                    type: 'GOOD_RESET_SETTER',
                    payload: true,
                });
                
            }).catch(error => {
                alert(error);
                if(error && error.response && error.response.status===401){
                    alert(`
                    There was a problem authenticating your credentials.
                     
                    Please Review your login credentials and try again.`)
                }
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};

export const apiPasswordUpdate = (resetKey, password) => {
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: `http://remily-api.pxpqa.com/password_reset/${resetKey}`,
                data: {
                    "password": password,
                }
            })
            .then(function (response) {
                dispatch({
                    type: 'GOOD_RESET_SETTER',
                    payload: false,
                });
            }).catch(error => {
                alert(error);
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const getCollections = (jwt, page, count) => {
    const Url = page && count ? `http://remily-api.pxpqa.com/collections/?count=999&page=${page}` : `http://remily-api.pxpqa.com/collections`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: Url,
                headers: requestData,
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
                dispatch({
                    type: 'GET_API_COLLECTIONS',
                    payload: response,
                });
            }).catch(error => {
                if(error){
                    console.log("getCollections erroring", error.message);
                }
                if(error.response.status===401){
                    alert(`
                    ${error}

                    We were unable to retrieve your collections. 
                    Please try logging back in.
                    `)
                }
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err.message)
        }
    }
};
export const getCollection = (jwt, collectionApiKey, page, count) => {
    const Url = page && count ? `http://remily-api.pxpqa.com/collections/${collectionApiKey}/?count=9999&page=${page}` : `http://remily-api.pxpqa.com/collections/${collectionApiKey}`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: Url,
                headers: requestData,
            })
            .then(function (response) {
                console.log("Get Collection response", response);
                getCollections(response.data.new_jwt, 1, 0);
                dispatch(setJwt(response.data.new_jwt)) ;
                dispatch({
                    type: 'GET_API_COLLECTION',
                    payload: response,
                });
            }).catch(error => {
                console.log(error);
                if(error && error.response && error.response.status===401){
                    alert(`
                    ${error}

                    We were unable to retrieve your collection. 
                    Please try logging back in.
                    `)
                }
                alert(`
                    ${error}
                    `)
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const createCollection = (collectionName, jwt) => {
    const Url = `http://remily-api.pxpqa.com/collections/`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: Url,
                data: {
                    "name": collectionName
                },
                headers: requestData,
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
            }).catch(error => {
                console.log(error);
                if(error && error.response && error.response.status===401){
                    alert(`
                    Your new Catagory did not Create. Please Try again.
                    `)
                }
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const destroyCollection = (collectionApiKey, jwt) => {
    const Url = `http://remily-api.pxpqa.com/collections/${collectionApiKey}/delete`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: Url,
                headers: requestData,
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
                dispatch({ type: 'UNSET_COLLECTION' })
            }).catch(error => {
                console.log(error);
                if(error && error.response && error.response.status===401){
                    alert(`
                    Your collection was not deleted. Please Try again.
                    `)
                }
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const getImage = (jwt, imageApiKey) => {
    const Url = `http://remily-api.pxpqa.com/images/${imageApiKey}`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: Url,
                headers: requestData,
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
                dispatch({
                    type: 'SET_IMAGE',
                    payload: response.data,
                });
            }).catch(error => {
                console.log(error);
                if(error && error.response && error.response.status===401){
                    alert(`
                    ${error}

                    We were unable to retrieve your collection. 
                    Please try logging back in.
                    `)
                }
                alert(`
                    ${error}
                    `)
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const destroyImage = (jwt, imageApiKey, collectionApiKey ) => {
    const Url = `http://remily-api.pxpqa.com/images/${imageApiKey}/delete`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: Url,
                headers: requestData,
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
            }).catch(error => {
                console.log(error);
                if(error && error.response && error.response.status===401){
                    alert(`
                    Your collection was not deleted. Please Try again.
                    `)
                }
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const updateObj = (jwt, obj, type, collectionApiKey) => {
    console.log(obj);
    
    const Url = `http://remily-api.pxpqa.com/${type}s/${obj.apikey}`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    const data = {}
    if(obj.name || obj.name ==='') data.name=obj.name;
    if(obj.summary || obj.summary ==='') data.summary=obj.summary;
    if(collectionApiKey) data.collection_id=collectionApiKey;
    return async (dispatch) => {
        try {
            await axios({
                method: 'put',
                url: Url,
                data,
                headers: requestData,
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
                
            }).catch(error => {
                console.log(error);
                if(error && error.response && error.response.status===401){
                    alert(`
                    Your collection was not updated. Please Try again.
                    `)
                }
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};

export const updateCollectionImage = (jwt, collectionApiKey, thumbnail_apikey) => {
    const Url = `http://remily-api.pxpqa.com/collections/${collectionApiKey}`
    const requestData ={ 'Authorization': `Bearer ${jwt}` }
    const data = {}
    data.thumbnail_apikey=thumbnail_apikey;
    return async (dispatch) => {
        try {
            await axios({
                method: 'put',
                url: Url,
                data,
                headers: requestData,
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
            }).catch(error => {
                console.log(error);
                if(error && error.response && error.response.status===401){
                    alert(` Your collection was not updated. Please Try again. `)
                }
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const addJournalEntry = (jwt, obj, collectionApiKey) => {
    console.log("addJournalEntry", obj);
    
    const Url = `http://remily-api.pxpqa.com/images`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    const data = {}
    if(obj.name) data.name=obj.name;
    if(obj.summary) data.summary=obj.summary||"Tells us your story";
    if(collectionApiKey) data.collection_id=collectionApiKey;
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: Url,
                data,
                headers: requestData,
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
            }).catch(error => {
                console.log(error);
                if(error && error.response && error.response.status===401){
                    alert(`
                    Your collection was not updated. Please Try again.
                    `)
                }
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
};
export const getPredictiveSearch = (jwt, searchTerm) => {
    var newTerm = searchTerm
    var splitTerm = newTerm.split(" ")
    var newTermLast = splitTerm[splitTerm.length - 1]
    var termWithOutLast = ""
    if(splitTerm.length > 2){
        termWithOutLast = splitTerm.slice(0, splitTerm.length-1)
        termWithOutLast = termWithOutLast.join(" ")
    }
    else if(splitTerm.length === 2){
        termWithOutLast = splitTerm.slice(0, splitTerm.length-1)
    }
    else{
        termWithOutLast = " "
    }
    const Url = `http://remily-api.pxpqa.com/autocomplete`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: Url,
                headers: requestData,
                data: {
                  "search": newTermLast.toLowerCase()
                }
            })
            .then(function (response) {
                if(response.data.length > 0 && response.data[0] !== newTermLast){
                    response.data.unshift(newTermLast)
                }
                if(response.data.length === 0 ){
                    response.data.unshift(" ")
                }
                for (let i = 0; i < response.data.length; i++) {
                    response.data[i] = { 
                        key: termWithOutLast + " " + response.data[i], 
                        value: termWithOutLast + " " + response.data[i], 
                        text: termWithOutLast + " " + response.data[i]
                    };
                }
                dispatch(setPredictiveSearch(response.data)) 
            }).catch(error => {
                console.log(error);
            });
        }catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
  };
  export const getSearchItems = (jwt, searchTerm) => {
    const Url = `http://remily-api.pxpqa.com/search`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: Url,
                headers: requestData,
                data: {
                  "search": searchTerm.toLowerCase(),
                }
            })
            .then(function (response) {
                dispatch(setJwt(response.data.new_jwt)) ;
                console.log("collections", {data: {collections: response.data.results.collections}});
                dispatch({
                    type: 'TOGGLE_SEARCH',
                    payload: {},
                });
                dispatch({
                    type: 'SET_SEARCH_COLLECTIONS',
                    payload: {data: {collections: response.data.results.collections}},
                });
                console.log("response", response);
                dispatch({
                    type: 'SET_SEARCH_COLLECTION',
                    payload: {data: {images: response.data.results.images, apikey: `search123`, name: "search"}},
                });
            //   dispatch(setSearchResponse(response.data.results)) 
            }).catch(error => {
                console.log(error);
            });
        }catch(err) {
            console.log('apiUserLogin Try error', err)
        }
    }
  };
  export const sendCardToken = (jwt, cardToken ) => {
    const Url = `http://remily-api.pxpqa.com/payments`
    const requestData ={
        'Authorization': `Bearer ${jwt}`
    }
    return async (dispatch) => {
        try {
            await axios({
                method: 'post',
                url: Url,
                headers: requestData,
                data: {stripe_card_token: cardToken}
            })
            .then(function (response) {
                console.log("cardToken sent successfully", response);
                if(response.data.success){
                    dispatch(componantDispatcher({type: "SET_PAYMENT_METHOD", payload: "success"}));
                }
            }).catch(error => {
                console.log(error);
            });
        } catch(err) {
            console.log('apiUserLogin Try error', err)
            dispatch(componantDispatcher({type: "SET_PAYMENT_METHOD", payload: "fail"}));
        }
    }
  };
