import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 1200px) {
    max-width: 966px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  @media (min-width: 1600px) {
    max-width: 1400px;
  }
`

export default Container;
