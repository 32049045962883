import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, typography } from '../assets/js/StyleConstants';

const InfoCardWrapper = styled.div`
  display: inline-block;
  background: ${colors.blue1};
  border-radius: 4px;
  height: auto;
  padding: 30px 25px;
`;

const Title = styled.h1`
  color: ${colors.white};
  ${typography.body1};
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: ${colors.white};
  ${typography.body2};
`;

const InfoCard = ({
  className,
  title,
  description
}) => (
  <InfoCardWrapper className={className}>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </InfoCardWrapper>
)

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default InfoCard;