import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { componantDispatcher } from '../actions/apiActions'

import Navbar from '../components/Navbar';
import Button from '../components/Button2';

const ContactWrapper = styled.div`
    width: 100%;
    background: linear-gradient( #FFF 60%, #EDF0F1 40%);
    display: flex;
    flex-flow: column;
`;
const ContactContentWrapper = styled.div`
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-flow: column;
    align-self: center;
    justify-content: center;
    padding: 40px 66px 0;
    @media(max-width: 767px){
        padding: 15px
    }
`;
const Title = styled.h1`
    color: #E64C3C;
    font-size: 30px;
    font-weight: 200;
    margin: 20px 0 !important;
    @media(max-width: 767px){
        margin: 0;
    }
`;
const PlansWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 37.5px;
    & #planBlock1{
        background-color: #E64C3C;
    }
    & #planBlock2{
        background-color: #3B97D3;
    }
    & #planBlock3{
        background-color: #2C3E50;
    }
    @media(max-width: 991px){
        flex-flow: column;
    }
    
    @media(max-width: 767px){
        padding: 15px
    }
`;
const PlanBlock = styled.div`
    margin: 18.5px;
    display: flex;
    flex-flow: column;
    padding: 28px 27px 35px;
    color: #FFFFFF;
    @media(max-width: 991px){
        flex-flow: row;
    }
    @media(max-width: 499px){
        flex-flow: column;
    }
`;

const PlanInfoWrapper = styled.div`
    display: flex;
    flex-flow: column;
    & h1 {
        font-size: 28px;
        font-weight: 200;
        margin: 5px 0 6px 0;
    }
    & h2 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
    }
    & ul {
        list-style-type: none;
        padding-left: 30px;
        & li:before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #FFFFFF;
            border-radius: 50%;
            margin: 0 9px 0 -17px;
        }
        & li {
            font-size: 12px;
            margin-bottom: 7px;
        }
    }
`;
const CtaWrapper = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1;
    justify-content: flex-end;
    & h3 {
        font-size: 45px;
        line-height: 45px;
        font-weight: 800;
        text-align: center;
        margin: 0;
    }
    & p {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        text-align: center;
        margin: 0;
    }
    
    @media(max-width: 991px){
        justify-content: center;
        margin-left: 25px;
    }
    @media(max-width: 499px){
        flex: unset;
        margin-left: 0;
    }
`;
const StyledButton = styled(Button)`
    width: 191px;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin-bottom: 6px;
    margin-top: 22px;
    background: #FFF;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    box-shadow: none;
`;
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }

  render() {
    return (
      <ContactWrapper>
        <Navbar></Navbar>
        <ContactContentWrapper>
            <Title>
                Choose your Remily plan
            </Title>
            <PlansWrapper>
                <PlanBlock id="planBlock1">
                    <PlanInfoWrapper>
                        <h1>Personal</h1>
                        <h2>Mauris commodo quam augue, nec tristique ligula faucibus at. </h2>
                        <ul>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                        </ul>
                    </PlanInfoWrapper>
                    <CtaWrapper>
                        <h3>$9.99</h3>
                        <p>monthly</p>
                        <StyledButton text="Get started" onClick={() =>{ this.props.componantDispatcher({type: "SET_PLAN_ID", payload:1}); this.props.history.push('/sign-up')}}/>
                    </CtaWrapper>
                </PlanBlock>
                <PlanBlock id="planBlock2">
                    <PlanInfoWrapper>
                        <h1>Family</h1>
                        <h2>Mauris commodo quam augue, nec tristique ligula faucibus at. </h2>
                        <ul>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                            <li>Nulla quis justo ac leo posuere laoreet.</li>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                        </ul>
                    </PlanInfoWrapper>
                    <CtaWrapper>
                        <h3>$29.99</h3>
                        <p>monthly</p>
                        <StyledButton text="Get started" onClick={() =>{ this.props.componantDispatcher({type: "SET_PLAN_ID", payload:2}); this.props.history.push('/sign-up')}}/>
                    </CtaWrapper>
                </PlanBlock>
                <PlanBlock id="planBlock3">
                    <PlanInfoWrapper>
                        <h1>Professional</h1>
                        <h2>Mauris commodo quam augue, nec tristique ligula faucibus at. </h2>
                        <ul>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                            <li>Aenean tincidunt felis in libero ultricies venenatis.</li>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                            <li>Nulla ac purus aliquam, eleifend ante ac, bibendum lorem.</li>
                        </ul>
                    </PlanInfoWrapper>
                    <CtaWrapper>
                        <h3>$49.99</h3>
                        <p>monthly</p>
                        <StyledButton text="Get started" onClick={() =>{ this.props.componantDispatcher({type: "SET_PLAN_ID", payload:3}); this.props.history.push('/sign-up')}}/>
                    </CtaWrapper>
                </PlanBlock>
            </PlansWrapper>
        </ContactContentWrapper>
      </ContactWrapper>
    );
  }
}

const mapStateToProps = state => {
    console.log(state);
    let returnedProps = {};
  
    // if(state.apiReducer && state.apiReducer.jwt){
    //     returnedProps.jwt = state.apiReducer.jwt;
    // };
    return {...returnedProps};
  }
  
  export default connect(mapStateToProps, {componantDispatcher}) (Contact);
