import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getCollection } from '../actions/apiActions';

const StyledDropzone = styled(Dropzone)`
    width: 100%;
    height: 100%;
    position: relative;
    :nth-child(1) {
        height: 100%;
        width: 100%;
        display: flex;
        height: 100%;;
        @media (max-width: 992px) {
            flex-flow: column;
        }
    }
`;
class DropzoneComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
        };
        this.onImageDrop = this.onImageDrop.bind(this);
        this.onImageEnter = this.onImageEnter.bind(this)
        this.onImageExit = this.onImageExit.bind(this)
    }
    onImageDrop(files) {
        this.props.imageUploadOverlayHandler(true, files);
        this.props.overlayHandler(false)
    }

    onImageEnter(){
        // console.log("onImageEnter");
        this.props.overlayHandler(true)
    }

    onImageExit(){
        // console.log("onImageExit");
        this.props.overlayHandler(false)
    }
    render() {
        return(
            <StyledDropzone
                id="dropZone"
                multiple={true}
                accept="image/*"
                onDrop={this.onImageDrop}
                onDragEnter={this.onImageEnter}
                onDragLeave={this.onImageExit}
                disableClick={true}
                style={{"background":"none"}}
            >
            {this.props.children}
            </StyledDropzone>
        )
    }
}
const mapStateToProps = state => {
    // console.log(state);
    let returnedProps = {};
    if(state.apiReducer.jwt){
      returnedProps.jwt = state.apiReducer.jwt;
    };    
    if(state.apiReducer.collection){
      returnedProps.collection = state.apiReducer.collection.data;
    }; 
    return {...returnedProps};
}
  
export default connect(mapStateToProps, {getCollection}) (DropzoneComponent);