import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CollectionList from './CollectionList';
import { getCollections} from '../actions/apiActions';

const CollectionListWrapper = styled.div`
    background: #F0F0F0;
    display: flex;
    flex: 3;
    max-width: 39%;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @media (max-width: 992px) {
        width: 100%;
        max-width: 100%;
        max-height: 190px;
        flex: 1 1 auto;
    }
    @media (min-width: 1280px) {
        width: 100%;
        max-width: 500px;
    }
`;
// const StyledUnasignedImages = styled.div`
//     margin: 0 auto 25px;
//     color: ${colors.gray2};
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     padding: 15px;
//     ${typography.body2};

//     &:hover {
//         color: ${colors.gray2};
//     }
// `;
class ColectionSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentCollectionApiKey: "none"
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.handleSetCurrentCollectionApiKey = this.handleSetCurrentCollectionApiKey.bind(this);

    }
    
  
    componentDidMount() {
        document.getElementById("actualCollectionList").addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.getElementById("actualCollectionList").removeEventListener('scroll', this.handleScroll);
    }
    componentWillReceiveProps(){
        this.isLazyloading = false
    }
    isLazyloading = false
    handleScroll = (event) => {
        let scrollDiv = event.target;
        let offset = 150;
        let scrollTop = event.target.scrollTop;
        
        if(!this.isLazyloading){
            if(this.props.collections.data.page_details.current_page < this.props.collections.data.page_details.total_pages){
                if(scrollDiv.scrollHeight - scrollTop - offset <= scrollDiv.clientHeight){
                    
                    this.isLazyloading = true
                    this.props.getCollections(this.props.jwt, this.props.collections.data.page_details.current_page + 1, this.props.collections.data.page_details.requested)
                };
            }
        }
    }   
    handleSetCurrentCollectionApiKey = key => {
        this.setState({
            currentCollectionApiKey: key,
        })
    }
    render() {
        return (
            <CollectionListWrapper id="ColectionSidebarID">
                <CollectionList 
                    collections={this.props.collections.data.collections ? this.props.collections.data.collections : []} 
                    getSpecificCollection={this.props.getSpecificCollection}
                    textSearch={this.props.textSearch} 
                    onSearch={this.props.onSearch} 
                    setCollectionIndex={this.props.setCollectionIndex} 
                    editObjSetter={this.props.editObjSetter} 
                    predictiveOptions={this.props.predictiveOptions} 
                    handleSearchEnter={this.props.handleSearchEnter} 
                    handleSearchClear={this.props.handleSearchClear} 
                    searchBool={this.props.searchBool}
                    jwt={this.props.jwt} 
                    setCurrentCollection={this.props.setCurrentCollection} 
                    collectionEditIconVarSetter={this.props.collectionEditIconVarSetter}
                    editIconVar={this.props.editIconVar}
                    currentCollectionApiKey={this.state.currentCollectionApiKey}
                    handleSetCurrentCollectionApiKey={this.handleSetCurrentCollectionApiKey}
                    imageUploadOverlayHandler={this.props.imageUploadOverlayHandler}
                    ifSearchSearchReverser={this.props.ifSearchSearchReverser}
                />
                {/* {window.innerWidth >= 880 && <StyledUnasignedImages onClick={()=>this.props.setCollectionIndex("none")}>
                    Uncategorised Images
                </StyledUnasignedImages>} */}
            </CollectionListWrapper> 
        );
    }
}
const mapStateToProps = state => {
    // console.log(state);
    let returnedProps = {};
    if(state.apiReducer.searchBool){
        returnedProps.searchBool = state.apiReducer.searchBool;
        if(state.apiReducer && state.apiReducer.searchCollections && state.apiReducer.searchCollections.data.collections){
            returnedProps.collections = state.apiReducer.searchCollections;
        };
        if(state.apiReducer && state.apiReducer.searchCollection){
            returnedProps.collection = state.apiReducer.searchCollection;
        }; 
    }
    if(!state.apiReducer.searchBool){
        if(state.apiReducer && state.apiReducer.collections && state.apiReducer.collections.data.collections){
            returnedProps.collections = state.apiReducer.collections;
        };
        if(state.apiReducer && state.apiReducer.collection){
            returnedProps.collection = state.apiReducer.collection;
        }; 
    }
    if(state.apiReducer && state.apiReducer.jwt){
      returnedProps.jwt = state.apiReducer.jwt;
    };     
    if(state.apiReducer && state.apiReducer.collection){
      returnedProps.collection = state.apiReducer.collection;
    }; 
    return {...returnedProps};
}
  
export default connect(mapStateToProps, {getCollections}) (ColectionSidebar);