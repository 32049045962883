import React, { Component } from 'react';
import styled from 'styled-components';
 
import { Button } from 'styled-button-component';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from 'styled-dropdown-component';

const StyledDropdownButton = styled(Button)`
    max-width: 100%;
    width: 407px;
    background-color: white;
    color: ${props => props.activated ? "black" : "#9D9D9D"};
    border-radius: 0;
    border-color: #3B97D3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after{
        color: #3B97D3;
    }
    &:hover{
        background-color: white !important;
        color: #3B97D3;
        border-color: #3B97D3 !important;
    }
    &:focus{
        background-color: white !important;
        color: #3B97D3;
        border-color: #3B97D3 !important;
        box-shadow: none;
    }
    @media(max-width: 500px){  
      max-width: unset;
      width: 100%;
    }
`;
const StyledDropdownMenu = styled(DropdownMenu)`
    max-width: 100%;
    width: 407px;
    & div:nth-child(even){
        border-top: solid 1px #707070;
        border-bottom: solid 1px #707070;
    }
`;
const StyledDropdownItem = styled(DropdownItem)`
    margin: 0 8.5px;
    padding: 11px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover{
        background-color: #3B97D310 !important;
        color: #3B97D3;
        cursor: pointer;
    }
    & p {
        margin: 0 !important;
        cursor: pointer;
    }
`;
 
export default class DropdownStyled extends Component {
    constructor(props) {
        super();
        this.state = {
          hidden: true,
          itemSelected: props.selectedPlanId ? true : false,
          selectedItem: (props.selectedPlanId === 1)? "Personal Plan: $9.99 /month" : (props.selectedPlanId === 2) ? "Family Plan: $29.99 / month" : (props.selectedPlanId === 3) ? "Professional Plan: $49.99 / month" :"Select your plan",
        };
        this.handleSelectedItem = this.handleSelectedItem.bind(this);
      }
     
      handleOpenCloseDropdown() {
        this.setState({
          hidden: !this.state.hidden,
        });
      }
      
      handleSelectedItem(selectedValue) {
        this.setState({
            selectedItem: selectedValue,
            itemSelected: true,
        });
    }
     
      render() {
        const { hidden } = this.state;
        return (
          <Dropdown className={this.props.className}>
            <StyledDropdownButton
              secondary
              dropdownToggle
              onClick={() => this.handleOpenCloseDropdown()}
              id="dropdownOpenerButton"
              activated={this.state.itemSelected}
              type="button"
            >
              {this.state.selectedItem}
            </StyledDropdownButton>
            <StyledDropdownMenu hidden={hidden} id="dropdownMenu">
              <StyledDropdownItem onClick={()=>{this.handleSelectedItem("Personal Plan: $9.99 /month"); this.handleOpenCloseDropdown()}}><p>Personal Plan:</p> <p>$9.99 /month</p></StyledDropdownItem>
              <StyledDropdownItem onClick={()=>{this.handleSelectedItem("Family Plan: $29.99 / month"); this.handleOpenCloseDropdown()}}><p>Family Plan:</p> <p>$29.99 / month</p></StyledDropdownItem>
              <StyledDropdownItem onClick={()=>{this.handleSelectedItem("Professional Plan: $49.99 / month"); this.handleOpenCloseDropdown()}}><p>Professional Plan:</p> <p>$49.99 / month</p></StyledDropdownItem>
            </StyledDropdownMenu>
          </Dropdown>
        );
      }
    };