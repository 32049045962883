import styled from 'styled-components';

// Retains the aspect ratio of images
const PhotoImage = styled.img`
  object-fit: cover;
  min-width: 150px;
  min-height: 150px;
  max-width: 250px;
  max-height: 250px;
`;

export default PhotoImage;
