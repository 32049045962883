import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  height: 42px;
  width: 191px;
  max-width: 100%;
  border: none;
  color: white;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3B97D3;
  &:hover {
    cursor: pointer; 
  }
`
const Button2 = ({ text, onClick, className, id }) => {
  return (
    <StyledButton className={className} onClick={onClick} id={id || ''}>
      {text}
    </StyledButton>
  );
};


export default Button2;
