import React from 'react';
import {injectStripe, CardElement} from 'react-stripe-elements';
import { componantDispatcher, sendCardToken } from '../actions/apiActions'
import styled from 'styled-components';
import { connect } from 'react-redux';

import Button from '../components/Button2';
import DropdownStyled from '../components/DropdownStyled';
import RadioStyled from '../components/RadioStyled';

const InputForm = styled.form`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 25px;
`;

const InfoWrapper = styled.div`
    max-width: 407px;
    width: 100%;
    height: 470px;
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    & h1 {
      color: #E64C3C;
      font-size: 30px;
      font-weight: 300;
      margin: 0;
    }
    & p {
      color: black;
      margin: -25px 0 0 0;
    }
`;

const CardElementWrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
`;
const StyledInput = styled.input`
    height: 40px;
    border: 1px solid #CDCDCD;
    padding: 10px;
    background: #D0D1D1;
    /* width: 100%; */
    & .InputContainer .InputElement::placeholder{
        color: #9D9D9D;
        font-size: 14px;
        -webkit-text-fill-color: #9D9D9D;
    }
`;
const StyledCardElement = styled(CardElement)`
    height: 40px;
    border: 1px solid #CDCDCD;
    padding: 10px;
    background: #D0D1D1;
    width: 100%;
    &::placeholder{
        color: #9D9D9D;
        font-size: 14px;
    }
`
const FlexRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const PaymentMethodWrapper = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    & p {
        margin: 0 0 10px 0;
        font-size: 16px;
        font-weight: 600;
    }
    & input {
        background-color: white;
        margin-right: 5px;
    }
`;

const StyledButton = styled(Button)`
    width: 47%;
    height: 42px;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin-bottom: 6px;
`;

class CheckoutForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedPlanId: props.selectedPlanId
      };
      this.forceNav = this.forceNav.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    handleFormSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let name = e.target[3].value
        console.log(name);
        this.props.stripe.createToken({type: 'card', name})
            .then((result) => {
                if(result.token){
                    console.log('Received Stripe token:', result.token);
                    this.props.sendCardToken(this.props.jwt, result.token);
                }
                if(result.error){
                    console.log('Received Stripe error:', result.error);
                }
            });
    };
    forceNav(navString){
        this.props.componantDispatcher({type: "LOGIN_ERROR", payload:{}});
        this.props.history.push(navString);
    }
    render() {
        return (
        <InputForm onSubmit={(e)=>{this.handleFormSubmit(e)}}>
            <InfoWrapper>
                <h1>Let’s pick a plan</h1>
                <DropdownStyled onClick={()=>console.log("dropdown")} selectedPlanId={this.state.selectedPlanId} />
                <PaymentMethodWrapper>
                    <p>Select payment method:</p>
                    <FlexRowWrapper>
                        <RadioStyled/>
                    </FlexRowWrapper>
                </PaymentMethodWrapper>
                <StyledInput id="paymentNameInput" placeholder="Name on Card"/>
                <CardElementWrapper>
                    <StyledCardElement onReady={(el) => el.focus()} />
                </CardElementWrapper>
                <FlexRowWrapper>
                    <StyledButton type="button" onClick={() => this.forceNav('/login')}  text="Cancel" />
                    <StyledButton type="submit" text="Finalize" />
                </FlexRowWrapper>
            </InfoWrapper>
        </InputForm>
        );
    }
}
function mapStateToProps(state) {
    console.log(state);
    let returnedProps = {};
    
    if(state.apiReducer){
        if(state.apiReducer.jwt){
            returnedProps.jwt = state.apiReducer.jwt;
        }; 
        if(state.apiReducer.paymentMethod){
            returnedProps.paymentMethod = state.apiReducer.paymentMethod;
        }; 
        if(state.apiReducer.selectedPlanId){
            returnedProps.selectedPlanId = state.apiReducer.selectedPlanId;
        };
    }
    return {...returnedProps};
  }

export default connect(mapStateToProps, {componantDispatcher, sendCardToken})(injectStripe(CheckoutForm));