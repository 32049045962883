import React from 'react';
import styled from 'styled-components';

import { colors } from '../assets/js/StyleConstants'

const LinkButton = styled.a`
  background: ${colors.blue3};
  border-radius: 4px;
  color: ${colors.white};
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: .5px;
  padding: 14px;
  text-align: center;

  &:hover {
    color: ${colors.white};
  }
`;

const Button = ({ children, className, href }) => <LinkButton href={href} className={className}>{children}</LinkButton>;

export default Button;
