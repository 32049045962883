import React, { Component } from 'react';
import styled from 'styled-components';

import Navbar from '../components/Navbar';

const ContactWrapper = styled.div`
    width: 100%;
    background: white;
`;
const InfoWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 56px 65px 77px 67px;
    background-color: white;
    & h1 {
      color: #E64C3C;
      font-size: 28px;
      font-weight: 300;
      margin: 0px 0 42px;
      letter-spacing: -.5px;
    }
    & p {
      color: black;
      font-size: 16px;
      font-weight: 400;
      margin: 0 0 22px;
    }
`;

class Contact extends Component {
  state = {
    email: '',
    password: '',
    signUpBool: false
  };
  handleChange = (event, key) => {
    const { value } = event.target;
    this.setState({
      [key]: value,
    });
  };

  render() {
    return (
      <ContactWrapper>
        <Navbar></Navbar>
        <InfoWrapper>
            <h1>Remily Terms and Conditions</h1>
            <h2>Welcome to Remily</h2>
            <p>
                We hope that by using our services you‘ll be able to create and preserve your family legacy like never before. As you get started, please take a few minutes to read our terms and conditions (which may change as needed so be sure to check them in the future as well).
            </p>
            <p>
                By using our services you agree to the following terms and conditions:
            </p>
            <p>
                Preventing abuses: Remily will take appropriate action against any user who violates rules and guidelines provided in these terms and conditions. That includes any abusive actions either on the Remily website or via our mobile app including posting or sharing defamatory content, spamming other Remily users, using harmful language or otherwise harmful conduct towards others.
            </p>
            <p>
                Content ownership and Remily product protection: You own the content you post using our services, but the concept, design, all website and mobile app features are protected by law and any attempt to recreate or otherwise adopt any aspect of Remily will result in legal course of action.
            </p>
            <p>
                Banning users and terminating accounts: Remily reserves the right to ban user and terminate accounts based on inappropriate, harmful or illegal activity on the website or mobile app.
            </p>
            <p>
                Governing law: The use of Remily services is subject to all local, state, national and international laws and regulations as they apply. For any claim, cause of action, or dispute, you agree that it will be resolved in a state court located in the U.S. state of Massachusetts.
            </p>
            <p>
                Liability: Remily is not legally or otherwise liable for any negative effects or results from the use of our services by any user.
            </p>
            <p>
                Cancelling subscriptions: You can cancel your Remily subscription at any time. For month-to-month subscribers, you will have access to all pictures and content you‘ve posted until the end of the month in which you cancel. For two to three year subscribers, you will have access to all pictures and content you‘ve posted through the year in which you cancel. When you cancel we will ask you if there was a specific reason to help us make any necessary improvements to Remily services.
            </p>
            <p>
                User age limits: Children under the age of 13 are not allowed to use Remily without adult supervision.
            </p>
            <p>
                Limitations: Remily can only be used for the specific purposes of creating and sharing pictures and content of and with family and friends, and with a larger audience if you choose to. Remily cannot be used in any other way.
            </p>
            <p>
                Termination: Remily reserves to right to terminate any user subscription if users violate any of the rules of guidelines regarding use of the Remily website or app.
            </p>
            <p>
                Service availability: While we are committed to providing the best service possible, Remily services can be canceled at any time without notification and we do not guarantee that service with be accessible every hour of every day.
            </p>
            <p>
                Linking to other websites: Remily is not responsible for the content of any links to other websites and users are responsible for reading 3rd party website privacy policies and terms and conditions.
            </p>
            <p>
                Enforcing terms and conditions: By signing up for a Remily subscription you have read and agree to our terms and conditions. 
            </p>
        </InfoWrapper>
      </ContactWrapper>
    );
  }
}

export default Contact
